import { useNavigate } from 'react-router-dom'
import Logo from 'assets/images/Periscope_Logo.svg'
import HelpIcon from 'assets/help_icon.png'

function MainHeader() {
  const navigate = useNavigate()
  const handleButtonClick = (route: string) => {
    navigate(route)
  }

  return (
    <div className="header w-screen justify-between h-20 bg-white flex border-b border-grayOutlineColor">
      <img
        src={Logo}
        className="h-8 m-auto mx-4 cursor-pointer"
        alt="Ship Project Logo"
        onClick={() => handleButtonClick('/')}
      />

      <div className="flex items-center h-full mx-4 gap-6">
        <img
          src={HelpIcon}
          className="w-5 h-auto cursor-pointer"
          onClick={() => handleButtonClick('/help-centre')}
        ></img>

        {/* <div className="w-12 h-12 bg-accentColor rounded-full">
          <p className="flex items-center justify-center h-full text-black font-semibold text-xl">JD</p>
        </div> */}
      </div>
    </div>
  )
}

export default MainHeader
