import Alert from '../components/Alert'

function WrongScreenSize() {
  return (
    <div className="min-h-screen">
      <Alert
        open
        type="warning"
        title="Not enough screen space!"
        alertText="This app requires larger screen width. If you're using this app on a phone, please rotate your device to landscape orientation."
      ></Alert>
    </div>
  )
}

export default WrongScreenSize
