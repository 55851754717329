import { forwardRef } from 'react'
import ReactSelect, { createFilter } from 'react-select'
import classNames from 'classnames'
import { FormGroupControlBaseProps } from './FormGroup'
import FormGroupControl from './FormGroupControl'
import { ReactComponent as CaretDownIcon } from 'assets/icons/caretDown.svg'
import { ReactComponent as ClearIcon } from 'assets/icons/xmark.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

export interface SelectOption {
  value: any
  label: string
  [key: string]: any
}

interface Props extends FormGroupControlBaseProps<SelectOption | null | undefined | any, SelectOption[]> {
  options: SelectOption[] | any
  placeholder?: string
  disabled?: boolean
  errorMessage?: string
  isClearable?: boolean
  isSearchable?: boolean
  defaultValue?: SelectOption[] | SelectOption | any
  isMulti?: boolean
  withoutBorder?: boolean
  id?: string
}

function DropdownIndicator() {
  return <CaretDownIcon fill="#667085" />
}

function CustomClearIcon() {
  return (
    <ClearIcon
      className="mr-1"
      fill="#667085"
    />
  )
}

function ClearIndicator(props: any) {
  const { children = <CustomClearIcon />, getStyles, innerProps } = props
  return (
    <div
      {...innerProps}
      style={getStyles('clearIndicator', props)}
    >
      <div style={{ padding: '0px 5px' }}>{children}</div>
    </div>
  )
}

function optionFormat(item: any, context: any) {
  const isSelected = context.selectValue.find(({ value }: any) => value === item.value)
  return (
    <div
      className={classNames('relative px-3 py-[0.6rem] mb-1 rounded-md text-sm lg:text-lg', {
        'selected-item bg-whisper-gray': !!isSelected,
      })}
    >
      {item.label}
      {item.description && <span className="ml-2 text-grayPlaceholder">[{item.description}]</span>}
      {!!isSelected && (
        <span className="check-icon-container absolute right-4 pl-3">
          <FontAwesomeIcon
            className="mt-0.5 ml-2 text-lightBlue"
            icon={faCheck}
          />
        </span>
      )}
    </div>
  )
}

const Select = forwardRef<HTMLInputElement, Props>(
  (
    {
      disabled,
      options,
      value,
      placeholder,
      errorMessage,
      helperText,
      onChange,
      isClearable = false,
      isSearchable = false,
      defaultValue,
      isMulti,
      id,
    },
    ref: any
  ) => (
    <FormGroupControl
      errorMessage={errorMessage}
      helperText={helperText}
    >
      <ReactSelect
        ref={ref}
        unstyled
        menuPortalTarget={document.body}
        isDisabled={disabled}
        placeholder={placeholder}
        classNamePrefix={'select-input'}
        menuPlacement={'auto'}
        options={options}
        value={value}
        filterOption={createFilter({ ignoreAccents: false })}
        isClearable={isClearable}
        isSearchable={isSearchable}
        defaultValue={defaultValue}
        isMulti={isMulti}
        onChange={(data: any) => {
          if (onChange) onChange(data)
        }}
        id={id}
        // closeMenuOnSelect={!isMulti}
        components={{ DropdownIndicator, ClearIndicator }}
        hideSelectedOptions={false}
        formatOptionLabel={optionFormat}
        classNames={{
          //   clearIndicator: (state) => '',
          container: (state) => {
            // console.log('state', state)
            return classNames('border rounded-lg border-grayOutlineColor', {
              'shadow-[0_0_5px_2px_rgba(46,165,219,1)]': state.isFocused,
              'bg-whisper-gray': disabled,
              'bg-white': !disabled,
              'text-grayPlaceholder': disabled,
            })
          },
          control: (state) => 'py-[0.65rem] px-[0.75rem]',
          //   dropdownIndicator: (state) => 'bg-blue',
          //   group: (state) => '',
          //   groupHeading: (state) => '',
          //   indicatorsContainer: (state) => '',
          //   indicatorSeparator: (state) => '',
          //   input: (state) => '',
          //   loadingIndicator: (state) => '',
          //   loadingMessage: (state) => '',
          menu: (state) => 'z-30 mt-2 p-1 pt-2 bg-white border rounded-lg border-grayOutlineColor',
          menuList: (state) => 'z-30',
          menuPortal: (state) => 'z-30',
          multiValue: (state) =>
            'mr-1 py-[0.1rem] px-1 border rounded border-grayOutlineColor inline-block font-medium text-[12px] leading-1 text-[#0B0D14] cursor-pointer',
          //   multiValueLabel: (state) => '',
          multiValueRemove: (state) => 'text-gray',
          //   noOptionsMessage: (state) => '',
          option: (state) => 'text-gray',
          placeholder: (state) => 'text-gray text-sm lg:text-lg',
          // singleValue: (state) => '',
          valueContainer: (state) => {
            // console.log('state', state)
            return classNames({
              'bg-whisper-gray': disabled,
              'bg-white': !disabled,
            })
          },
        }}
      />
    </FormGroupControl>
  )
)

export default Select
