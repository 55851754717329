import { ReactComponent as Icon } from 'assets/icons/arrowDownCircled.svg'
import classNames from 'classnames'

interface Props {
  measurement: string
  value: number
  unit: string
  percentage?: number
  isReversed?: boolean
}

function DataMetricsBlock({ measurement, value, unit, percentage = 0, isReversed = false }: Props) {
  let statusColor
  const statusImageClassName = percentage < 0 ? '' : 'rotate-180'

  if (isReversed) {
    statusColor = percentage > 0 ? 'bad' : 'good'
  } else {
    statusColor = percentage < 0 ? 'bad' : 'good'
  }

  return (
    <div
      className={classNames(
        `relative p-5 flex flex-col rounded-lg bg-pure-white border`,
        statusColor === 'bad' ? 'border-coral-blaze' : 'border-lush-meadow'
      )}
    >
      <div
        className={classNames(
          `absolute top-[-0.938rem] right-[-0.938rem] w-[1.875rem] h-[1.875rem] flex justify-center items-center rounded-full`,
          statusColor === 'bad' ? 'bg-coral-blaze' : 'bg-lush-meadow'
        )}
      >
        <Icon className={classNames('w-6 h-6 fill-pure-white', statusImageClassName)} />
      </div>
      <div className="text-xl leading-7 text-cosmic-black font-semibold mb-5">{measurement}</div>
      <div className="flex justify-between mt-auto">
        <div>
          <div className="text-xl leading-7 text-silver-mist font-semibold mb-1">{unit}</div>
          <div className="text-[2rem] leading-[2rem] text-cosmic-black font-semibold">{value}</div>
        </div>
        <div
          className={classNames(
            `text-2xl leading-8 font-semibold self-end `,
            statusColor === 'bad' ? 'text-coral-blaze' : 'text-lush-meadow'
          )}
        >
          {percentage} %
        </div>
      </div>
    </div>
  )
}

export default DataMetricsBlock
