import { useEffect, useState } from 'react'
import ReusableInputField from '../components/Input'
import { useLocation, useNavigate } from 'react-router-dom'
import MainLogo from 'assets/images/Periscope_Logo_Powered.svg'
import EyeIcon from '../assets/eye_icon.png'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { RootState, validateAndSetNewPassword } from 'store'
import Button from 'components/Button'

function ChangePassword() {
  const [newPassword, setNewPassword] = useState<string>('')
  const [confirmCurrentPassword, setConfirmCurrentPassword] = useState<string>('')
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)
  const [notValid, setNotValid] = useState<string>('')
  const location = useLocation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)
  const oobCode = searchParams.get('oobCode')

  const { authError, passwordChanged, authIsLoading } = useAppSelector((state: RootState) => {
    return state.auth
  })

  const handleNewPassword = (newPassword: string) => {
    setNewPassword(newPassword)
  }

  const handleConfirmNewPassword = (confirmNewPassword: string) => {
    setConfirmCurrentPassword(confirmNewPassword)
  }

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword)
  }

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const handleSubmit = (event: any) => {
    event.preventDefault()
    if (newPassword === confirmCurrentPassword && newPassword.length >= 6) {
      setNotValid('')
      if (oobCode !== null) {
        dispatch(validateAndSetNewPassword({ actionCode: oobCode, password: newPassword }))
      } else {
        setNotValid('Invalid password reset link')
      }
    }

    if (newPassword !== confirmCurrentPassword) {
      setNotValid("Passwords don't match")
    }

    if (newPassword.length < 6) {
      setNotValid('Password is too short (at least 6 symbols)')
    }
  }

  useEffect(() => {
    setNotValid(authError || '')
  }, [authError])

  useEffect(() => {
    if (passwordChanged === true) {
      // Session is not automatically created for the user after the reset,
      // but if it was still active, user can be redirected to the main page
      navigate('/')
    }
  }, [passwordChanged])

  return (
    <div className="pt-16 pb-12 bg-primary not-phone:h-screen flex flex-1 flex-col justify-center overflow-x-hidden">
      <div>
        <div className="sm:mx-auto sm:w-full sm:max-w-sm mb-12">
          <img
            className="mx-auto h-18 w-auto"
            src={MainLogo}
            alt="Shipprojects"
          />
        </div>

        <div className="flex justify-center mt-4">
          <div className="bg-white px-8 py-8 border border-grayOutlineColor rounded-xl w-[508px]">
            <div className="flex font-semibold mb-4 text-2xl lg:text-4xl">Update your password</div>

            <p className="text-sm font-base text-[#373F52] mb-4">
              You need to update your password because this is the first time you are signing in, or because your
              password has expired.
            </p>

            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <ReusableInputField
                  label="New Password"
                  type={showNewPassword ? 'text' : 'password'}
                  value={newPassword}
                  onChange={handleNewPassword}
                  placeholder="Enter new password"
                  height="48px"
                  icon={EyeIcon}
                  onIconClick={toggleNewPasswordVisibility}
                  required
                  className="text-lg"
                />
              </div>

              <div className="mb-4">
                <ReusableInputField
                  label="Confirm New Password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  value={confirmCurrentPassword}
                  onChange={handleConfirmNewPassword}
                  placeholder="Confirm new password"
                  height="48px"
                  icon={EyeIcon}
                  onIconClick={toggleConfirmPasswordVisibility}
                  required
                  className="text-lg"
                />
              </div>

              <div className="text-sm">
                {notValid === '' && <p className="text-black mb-4">Password should be at least 6 symbols</p>}
                {notValid !== '' && <p className="text-[#E1341E] mb-4">{notValid}</p>}
              </div>

              <div className="flex flex-col gap-4 justify-center mt-8">
                <div>
                  <Button
                    secondary
                    fullWidth
                    semibold
                    large
                    loading={authIsLoading}
                  >
                    Update password
                  </Button>
                </div>

                <div>
                  <Button
                    type="button"
                    onClick={() => navigate('/')}
                    primary
                    semibold
                    fullWidth
                    large
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangePassword
