import { useDispatch, useSelector } from 'react-redux'
import { analyticsState, changeErrorStatus } from '../../../store/slices/analyticsSlice'
import {
  changeGraphIsFetching,
  updateCurrentChartData,
  useGetSOGInPowerQuery,
  useGetTotalFuelConsumedBarChartInRangeQuery,
} from '../../../store'
import { useEffect } from 'react'
import { SplitSensor } from 'interfaces/vessel'

interface Props {
  graphNr: number
  chartType: string
  queryParams: {
    vesselId: string
    mainEngineFlowMeters?: SplitSensor[]
    auxEngineFlowMeters?: SplitSensor[]
  }
}

function TotalFuelConsumedBarChartInRangeLoader({ graphNr, chartType, queryParams }: Props) {
  const dispatch = useDispatch()
  const { dateFilters, graph1IsFetching, graph2IsFetching } = useSelector(analyticsState)

  const { data, isFetching, error } = useGetTotalFuelConsumedBarChartInRangeQuery({
    vesselId: queryParams.vesselId,
    startTime: dateFilters.startTime,
    endTime: dateFilters.endTime,
    mainEngineFlowMeters: queryParams.mainEngineFlowMeters,
    auxEngineFlowMeters: queryParams.auxEngineFlowMeters,
  })

  useEffect(() => {
    if (data && !graph1IsFetching && !graph2IsFetching) {
      dispatch(updateCurrentChartData({ graphNr, chartType, data }))
    }
  }, [data, graph1IsFetching, graph2IsFetching])

  useEffect(() => {
    dispatch(changeGraphIsFetching({ isFetching, graphNr }))
  }, [isFetching])

  useEffect(() => {
    dispatch(changeErrorStatus({ graphNr, error }))
  }, [error])

  return <></>
}

export default TotalFuelConsumedBarChartInRangeLoader
