import ButtonGroup from 'components/ButtonGroup'
import ContentWrapper from 'components/layout/ContentWrapper'
import PageHeading from 'components/typography/PageHeading'
import { PerformanceOverviewPeriod, VesselStatsData } from 'interfaces/vessel'
import { useEffect, useMemo, useState } from 'react'
import { useAppSelector } from 'hooks/useReduxHooks'
import { RootState, useGetAllVesselsQuery } from 'store'
import VesselPerformanceTable from './components/VesselPerformanceTable'
import FuelConsumed from './components/FuelConsumed'
import FuelEfficiencySFOC from './components/FuelEfficiencySFOC'
import PieChart from 'components/chart/PieChart'

function VesselComparison() {
  const [selectedPeriod, setSelectedPeriod] = useState<PerformanceOverviewPeriod>('previous_week')
  const [selectedVesselIds, setSelectedVesselIds] = useState<string[]>([])
  const { user } = useAppSelector((state: RootState) => {
    return state.auth
  })
  const { data: vesselList, isFetching: vesselListFetching } = useGetAllVesselsQuery(user?.uid || '')
  const shadesOfBlue = useMemo(() => {
    return Array.from({ length: 60 }, (_, i) => `hsl(210, 100%, ${i + 25}%)`)
  }, [])
  const selectedColors = pickColorsFromArray(selectedVesselIds.length, shadesOfBlue)
  const selectedVessels = vesselList?.filter((vessel) => selectedVesselIds.includes(vessel.id))

  const vesselChartData = useMemo(() => {
    return selectedVessels?.map((vessel) => {
      return {
        vesselName: vessel.name,
        color: selectedColors[selectedVessels.indexOf(vessel)],
        fuelConsumedAmount: vessel.performance_overview[selectedPeriod].find(
          (item) => item.name === 'totalFuelConsumed'
        )?.value,
        co2EmissionsAmount: vessel.performance_overview[selectedPeriod].find(
          (item) => item.name === 'totalCO2Emissions'
        )?.value,
        fuelEfficiencyAmount: vessel.performance_overview[selectedPeriod].find(
          (item) => item.name === 'averageFuelEfficiency'
        )?.value,
        sfocAmount: vessel.performance_overview[selectedPeriod].find((item) => item.name === 'averageSFOC')?.value,
      }
    })
  }, [selectedVessels, selectedPeriod])

  const rangeButtons = [
    { label: 'Last week', value: 'previous_week' },
    { label: 'Last month', value: 'previous_month' },
    { label: 'Last 3 months', value: 'previous_3_months' },
  ]

  const fuelConsumedData =
    vesselChartData?.map((vessel) => ({
      name: vessel.vesselName,
      value: vessel.fuelConsumedAmount || 0,
      unit: 'mt',
      color: vessel.color,
    })) || []

  const co2Data =
    vesselChartData?.map((vessel) => ({
      name: vessel.vesselName,
      value: vessel.co2EmissionsAmount || 0,
      unit: 'mt',
      color: vessel.color,
    })) || []

  function updateRange(value: PerformanceOverviewPeriod) {
    setSelectedPeriod(value)
  }

  function changeselectedVesselIds(vesselId: string) {
    if (selectedVesselIds.includes(vesselId)) {
      setSelectedVesselIds(selectedVesselIds.filter((vessel) => vessel !== vesselId))
    } else {
      setSelectedVesselIds([...selectedVesselIds, vesselId])
    }
  }

  function pickColorsFromArray(numberOfColors: number, colorsArray: string[]) {
    const colors = []
    const interval = Math.floor(colorsArray.length / numberOfColors)
    for (let i = 0; i < numberOfColors; i++) {
      colors.push(colorsArray[i * interval])
    }
    return colors
  }

  useEffect(() => {
    if (vesselList) {
      setSelectedVesselIds(vesselList.map((vessel) => vessel.id))
    }
  }, [vesselList])

  return (
    <ContentWrapper>
      <div className="flex justify-between mb-6">
        <PageHeading>Vessel Comparison</PageHeading>
        <div className="flex gap-4">
          <ButtonGroup
            buttons={rangeButtons}
            value={selectedPeriod}
            onChange={updateRange}
            disabled={user?.isDemo || vesselListFetching}
          />
        </div>
      </div>

      <div className="mb-10">
        <VesselPerformanceTable
          vesselList={vesselList}
          selectedPeriod={selectedPeriod}
          loading={vesselListFetching}
          selectedVessels={selectedVessels}
          onVesselCheck={changeselectedVesselIds}
        />
      </div>

      <div className="flex max-lg:flex-col gap-6 xl:gap-10">
        <div className="lg:w-1/2 xl:flex-1">
          {vesselChartData && (
            <div className="mb-6 xl:mb-10">
              <FuelConsumed
                data={vesselChartData}
                loading={vesselListFetching}
              />
            </div>
          )}

          {vesselChartData && (
            <div className="mb-6 xl:mb-10">
              <FuelEfficiencySFOC
                data={vesselChartData}
                loading={vesselListFetching}
              />
            </div>
          )}
        </div>

        <div className="max-lg:w-full max-lg:flex flex-row lg:w-1/2 xl:w-[520px] 3xl:w-[600px] gap-6">
          {vesselChartData && (
            <div className="mb-6 xl:mb-10 flex-1">
              <PieChart
                data={fuelConsumedData}
                loading={vesselListFetching}
                title="Fuel consumed"
                tooltipText="Total fuel consumed based on selected interval"
                tooltipId="fuel-consumed-pie-chart"
              />
            </div>
          )}

          {vesselChartData && (
            <div className="mb-6 xl:mb-10 flex-1">
              <PieChart
                data={co2Data}
                loading={vesselListFetching}
                title="CO2 emitted"
                tooltipText="Total CO2 emitted based on selected interval"
                tooltipId="co2-emitted-pie-chart"
              />
            </div>
          )}
        </div>
      </div>
    </ContentWrapper>
  )
}

export default VesselComparison
