// import OkIcon from '../assets/ok_icon.png'
import { ReactComponent as OkSvg } from 'assets/icons/CheckCircleRegular.svg'
import { ReactComponent as DangerSvg } from 'assets/icons/warningOctagon.svg'
import { ReactComponent as WarningSvg } from 'assets/icons/WarningOctagonFilled.svg'
import { ReactComponent as ResetSvg } from 'assets/icons/ResetPassword.svg'
import Button from './Button'
import { useEffect, useRef } from 'react'
import classNames from 'classnames'

type AlertDialogType = 'default' | 'error' | 'success' | 'delete' | 'reset-password' | 'warning' | 'danger'

const DangerIcon = () => {
  return <DangerSvg className="h-6 w-6 fill-fiery-coral" />
}

const WarningIcon = () => {
  return <WarningSvg className="h-6 w-6 fill-sunburst-orange" />
}

const ResetIcon = () => {
  return <ResetSvg className="h-6 w-6 fill-lush-meadow" />
}

const OkIcon = () => {
  // fill-lush-meadow
  // fill-vibrant-fern
  return <OkSvg className="h-6 w-6 fill-lush-meadow" />
}

const AlertIcon = ({ type }: { type: AlertDialogType }) => {
  if (type === 'error' || type === 'danger') return <DangerIcon />
  if (type === 'warning') return <WarningIcon />
  if (type === 'success') return <OkIcon />
  if (type === 'reset-password') return <ResetIcon />

  return <OkIcon />
}

interface AlertProps {
  open?: boolean
  type?: AlertDialogType
  title?: string
  alertText?: string
  eventButtonText?: string
  hasCancelButton?: boolean
  onCancel?: () => void
  onEvent?: () => void
  onOutsideClick?: () => void
}

function Alert({
  open,
  type,
  hasCancelButton,
  title,
  eventButtonText,
  alertText,
  onCancel,
  onEvent,
  onOutsideClick,
}: AlertProps) {
  const alertBox = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (!alertBox.current) return
      if (!alertBox.current.contains(event.target as any)) {
        if (onOutsideClick && typeof onOutsideClick === 'function') {
          onOutsideClick()
        }
      }
    }

    document.addEventListener('click', handler, true)

    return () => {
      document.removeEventListener('click', handler, true)
    }
  }, [])

  return (
    <>
      {open && (
        <div className="flex fixed top-0 left-0 items-center justify-center w-full h-full bg-soft-whisper z-50">
          <div
            ref={alertBox}
            className="flex items-center flex-col gap-4 absolute p-6 w-408 rounded-12 bg-white shadow-lg"
          >
            {type && type !== 'default' && (
              <div
                className={classNames(`flex justify-center items-center w-12 h-12 rounded-full`, {
                  'bg-mint-breeze': type === 'success' || type === 'reset-password',
                  'bg-pale-sunrise': type === 'warning',
                  'bg-blush-whisper': type === 'error' || type === 'danger',
                })}
              >
                <AlertIcon type={type} />
              </div>
            )}

            {title && <p className="font-bold">{title}</p>}

            {alertText && <p className="text-center">{alertText}</p>}

            <div className="flex gap-4 w-full">
              {hasCancelButton && (
                <Button
                  onClick={onCancel}
                  primary
                  large
                  semibold
                  className="flex-1"
                >
                  Cancel
                </Button>
              )}

              {eventButtonText && (
                <Button
                  onClick={onEvent}
                  secondary={type !== 'danger'}
                  danger={type === 'danger'}
                  large
                  semibold
                  className="flex-1"
                >
                  {eventButtonText}
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Alert
export type { AlertProps }
