import DataMetricsBlock from 'components/DataMetricsBlock'
import { PerformanceOverviewPeriod, VesselData } from 'interfaces/vessel'
import { extractPerformanceData } from 'shared/vessel/extractPerformanceData'

interface Props {
  vessel: VesselData | null
  selectedPeriod: PerformanceOverviewPeriod
}

export interface IKeyValuePair {
  [key: string]: string
}

function SingleDataMetrics({ vessel, selectedPeriod }: Props) {
  const perfData = extractPerformanceData(vessel, selectedPeriod)

  return (
    <>
      <DataMetricsBlock
        measurement="Total fuel consumed"
        value={perfData.totalFuelConsumedData?.value || 0}
        unit="mt"
        percentage={perfData.totalFuelConsumedData?.percentageChange || 0}
        isReversed
      />
      <DataMetricsBlock
        measurement="Average fuel efficiency"
        value={perfData.averageFuelEfficiency?.value || 0}
        unit="kg/nm"
        percentage={perfData.averageFuelEfficiency?.percentageChange || 0}
        isReversed={true}
      />
      <DataMetricsBlock
        measurement="Total C02 emitted"
        value={perfData.totalCO2Emissions?.value || 0}
        unit="mt"
        percentage={perfData.totalCO2Emissions?.percentageChange || 0}
        isReversed={true}
      />
      <DataMetricsBlock
        measurement="SFOC"
        value={perfData.averageSFOC?.value || 0}
        unit="g/kWh"
        percentage={perfData.averageSFOC?.percentageChange || 0}
        isReversed={true}
      />
      <DataMetricsBlock
        measurement="Total distance sailed"
        value={perfData.totalDistance?.value || 0}
        unit="nm"
        percentage={perfData.totalDistance?.percentageChange || 0}
      />
    </>
  )
}

export default SingleDataMetrics
