import { useNavigate } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import InfoIcon from '../assets/info_icon.png'
import ContentWrapper from '../components/layout/ContentWrapper'
import { RootState, useGetVesselListQuery } from '../store/'
import { VesselStatsData } from 'interfaces/vessel'
import PageHeading from 'components/typography/PageHeading'
import CustomTable, {
  CustomTableContentRow,
  CustomTableHeaderCol,
  CustomTableSettings,
} from 'components/tables/CustomTable'
import Percentage from 'components/Percentage'
import { useAppSelector } from 'hooks/useReduxHooks'
import { getUTCDateString } from 'shared/date/getUTCDateString'
import { getUTCTimeString } from 'shared/date/getUTCTimeString'

function Vessels() {
  const { user } = useAppSelector((state: RootState) => {
    return state.auth
  })
  const { data: vesselList, isFetching: vesselListFetching } = useGetVesselListQuery(user?.uid || '')

  const navigate = useNavigate()

  function handleVesselClick(vessel: VesselStatsData) {
    if (!vessel.id) return
    navigate(`/performance-overview/${vessel.id}`, {
      state: { vesselData: vessel },
    })
  }

  const tableHeaderData: CustomTableHeaderCol[] = [
    {
      id: '0',
      content: <p>Name</p>,
    },
    {
      id: '1',
      content: <p>Vessel type</p>,
    },
    {
      id: 'sfoc',
      content: (
        <>
          <p>SFOC [g/kWh]</p>
          <img
            src={InfoIcon}
            className="w-4 h auto"
            data-tooltip-id="sfoc-tooltip"
            data-tooltip-delay-hide={200}
          ></img>
          <Tooltip
            id="sfoc-tooltip"
            border="1px solid #8990A3"
            content="Specific Fuel Oil Consumption (SFOC) based on last week gathared data"
            style={{
              width: '275px',
              borderRadius: '8px',
              backgroundColor: 'white',
              color: '#5D657A',
            }}
          />
        </>
      ),
    },
    {
      id: 'fuelEfficiency',
      content: (
        <>
          <p>Fuel efficiency [kg/nm]</p>
          <img
            src={InfoIcon}
            className="w-4 h auto"
            data-tooltip-id="fuel-efficiency-tooltip"
            data-tooltip-delay-hide={200}
          ></img>
          <Tooltip
            id="fuel-efficiency-tooltip"
            border="1px solid #8990A3"
            content="Fuel efficiency based on last week gathared data"
            style={{
              width: '275px',
              borderRadius: '8px',
              backgroundColor: 'white',
              color: '#5D657A',
            }}
          />
        </>
      ),
    },
    {
      id: 'lastUpdated',
      content: <p>Last updated</p>,
    },
  ]

  const tableRowData: CustomTableContentRow[] =
    vesselList?.map((vessel, index) => {
      const timestamp = vessel.last_updated?._seconds || 0
      const lastUpdated =
        timestamp > 0
          ? `${getUTCDateString(new Date(timestamp * 1000))}, ${getUTCTimeString(new Date(timestamp * 1000))}`
          : 'No data available'

      return {
        id: vessel.id.toString(),
        haystack: [vessel.name.toLowerCase(), vessel.imo?.toString() || ''],
        onRowClick: () => handleVesselClick(vessel),
        cols: [
          {
            id: 'name',
            value: vessel.name,
            content: (
              <>
                <div>
                  <p className="mb-1">{vessel.name}</p>
                  <p className="text-grayText">IMO {vessel.imo}</p>
                </div>
              </>
            ),
          },
          {
            id: 'vesselType',
            value: vessel.vessel_type,
            content: <p>{vessel.vessel_type}</p>,
          },
          {
            id: 'sfoc',
            value: vessel.sfoc.value,
            content: (
              <>
                <p>{vessel.sfoc.value}</p>
                <Percentage
                  percentage={vessel.sfoc?.percentageChange || 0}
                  isReversed={true}
                ></Percentage>
              </>
            ),
          },
          {
            id: 'fuelEfficiency',
            value: vessel.fuel_efficiency.value,
            content: (
              <>
                <p>{vessel.fuel_efficiency.value}</p>
                <Percentage
                  percentage={vessel.fuel_efficiency?.percentageChange || 0}
                  isReversed={true}
                ></Percentage>
              </>
            ),
          },
          {
            id: 'lastUpdated',
            value: timestamp,
            content: <p>{lastUpdated}</p>,
          },
        ],
      }
    }) || []

  const columnClassNames = [
    'flex-1 min-w-44',
    'flex-1 min-w-44',
    'flex-1 min-w-44',
    'flex-1 min-w-52',
    'flex-1 min-w-44',
  ]

  const tableSettings: CustomTableSettings = {
    heading: 'Vessels',
    text: `Your fleet consists of ${tableRowData.length} vessels`,
    searchable: true,
    sortable: true,
    horizontallyScrollable: true,
    columnExtraClassNames: columnClassNames,
    // smallText: true,
  }

  return (
    <ContentWrapper>
      <div className="flex justify-between mb-6">
        <PageHeading>Fleet overview</PageHeading>
      </div>

      <CustomTable
        settings={tableSettings}
        headerData={tableHeaderData}
        rowData={tableRowData}
        isLoading={vesselListFetching}
      />
    </ContentWrapper>
  )
}

export default Vessels
