import classNames from 'classnames'

interface Props {
  children: any
  className?: string
  maxHeight?: string
}

function SidePanel({ children, className, maxHeight = 'calc(100vh - 5rem)' }: Props) {
  return (
    <div
      className={classNames('side-panel overflow-x-auto bg-white flex border-grayOutlineColor', className)}
      style={{ maxHeight }}
    >
      {children}
    </div>
  )
}

export default SidePanel
