import { useState, useEffect } from 'react'
import { RadioGroup } from '@headlessui/react'
import { ReactComponent as CircleIcon } from '../../assets/icons/circle.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'

interface RadioButtonGroupOption {
  name: string
  label: string
  description?: string
}

interface RadioOptionsProps {
  options: RadioButtonGroupOption[]
  inline?: boolean
  hideCheck?: boolean
  textCenter?: boolean
  textStrong?: boolean
}

function RadioOptions({ options, inline, hideCheck, textCenter, textStrong }: RadioOptionsProps) {
  function getClassNames(active: boolean, checked: boolean, inline: boolean) {
    return classNames(
      'radio-button-group-option',
      'bg-white',
      'relative',
      'flex',
      'cursor-pointer',
      'px-3 py-3',
      'border rounded-lg border-grayOutlineColor',
      {
        'shadow-[0_0_4px_1px_rgba(46,165,219,0.75)]': checked,
        'border-lightBlue': checked,
        'flex-1': inline,
      }
    )
  }

  return (
    <>
      {options.map((option) => (
        <RadioGroup.Option
          key={option.name}
          value={option.name}
          className={({ active, checked }) => getClassNames(active, checked, inline ? true : false)}
        >
          {({ active, checked }) => {
            const checkIcons = checked ? (
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="h-5 w-5 text-lightBlue"
              />
            ) : (
              <CircleIcon
                className="h-5 w-5"
                fill="#F0F1F5"
              />
            )
            return (
              <>
                <div
                  className={classNames('flex w-full items-center', {
                    'justify-center': textCenter,
                    'justify-between': !textCenter,
                  })}
                >
                  <div className={classNames('flex items-center', { 'justify-center': textCenter })}>
                    <div className={`${textStrong ? 'text-lg max-xl:text-sm' : 'text-sm'}`}>
                      <RadioGroup.Label
                        as="p"
                        className={`${textStrong ? 'font-bold' : 'font-medium'}`}
                      >
                        <span>{option.label}</span>
                        {option.description && <span className="ml-2 text-grayPlaceholder">{option.description}</span>}
                      </RadioGroup.Label>
                    </div>
                  </div>
                  {!hideCheck && checkIcons}
                </div>
              </>
            )
          }}
        </RadioGroup.Option>
      ))}
    </>
  )
}

interface RadioButtonGroupProps {
  onChange: (_value: string) => void
  options: RadioButtonGroupOption[]
  groupLabel?: string
  initialValue?: string
  className?: string
  inline?: boolean
  hideCheck?: boolean
  textCenter?: boolean
  textStrong?: boolean
}

function RadioButtonGroup({
  onChange,
  options,
  groupLabel,
  initialValue,
  className,
  inline,
  hideCheck,
  textCenter,
  textStrong,
}: RadioButtonGroupProps) {
  const [selectedOption, setSelectedOption] = useState<string | undefined>(() => setInitialState())
  // const [selectedOption, setSelectedOption] = useState(options[0].name)

  function setInitialState() {
    if (initialValue) {
      return initialValue
    }
    return undefined
  }

  useEffect(() => {
    if (selectedOption) {
      onChange(selectedOption)
    }
  }, [selectedOption])

  return (
    <div className={classNames('radio-button-group w-full', { inline: inline }, className)}>
      <RadioGroup
        value={selectedOption}
        onChange={setSelectedOption}
      >
        {groupLabel && groupLabel.length > 0 && <RadioGroup.Label className="sr-only">{groupLabel}</RadioGroup.Label>}
        <div className={classNames('space-y-2', { 'flex flex-row': inline })}>
          <RadioOptions
            options={options}
            inline={inline}
            hideCheck={hideCheck}
            textCenter={textCenter}
            textStrong={textStrong}
          />
        </div>
      </RadioGroup>
    </div>
  )
}

export type { RadioButtonGroupOption }
export default RadioButtonGroup
