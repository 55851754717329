import { Outlet } from 'react-router-dom'

function RootLayout() {
  return (
    <>
      <div className="not-phone:max-h-screen overflow-hidden">
        <Outlet />
      </div>
    </>
  )
}

export default RootLayout
