import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Legend,
  CategoryScale,
  BarElement,
  Tooltip as ChartTooltip,
  ChartOptions,
  ChartData,
} from 'chart.js'
import { VesselData } from 'interfaces/vessel'
import { Bar } from 'react-chartjs-2'
import { useGetCO2EmissionsBarChartInRangeQuery } from 'store'
import InfoIcon from 'assets/info_icon.png'
import { Tooltip } from 'react-tooltip'
import NoData from 'components/utility/NoData'
import BreakdownTable from 'components/tables/BreakdownTable'
import Loader from 'components/Loader'
import { getUTCDateString } from 'shared/date/getUTCDateString'

ChartJS.register(LinearScale, PointElement, LineElement, CategoryScale, BarElement, Legend, ChartTooltip)
ChartJS.defaults.font.family = 'Lato'
ChartJS.defaults.font.size = 14

function getEngineLabel(vessel: VesselData | null, flowMeterId: number) {
  if (!vessel) return
  return vessel.main_engines.find((engine: any) =>
    engine.flowMeter.find((sensor: any) => sensor.sensorId == flowMeterId)
  )
    ? 'M/E'
    : 'A/E'
}

interface ChartProps {
  barChartData: any
  barChartOptions: any
  isFetching: boolean
}

function TotalCO2EmittedChart({ barChartData, barChartOptions, isFetching }: ChartProps) {
  const dataAvailable = barChartData?.datasets[0]?.data?.length > 0 || barChartData?.datasets[1]?.data?.length > 0

  return (
    <>
      {isFetching ? (
        <Loader />
      ) : !dataAvailable ? (
        <NoData
          type="warning"
          title="No data available for selected period"
          text="Please, try different date range."
        />
      ) : (
        <Bar
          options={barChartOptions}
          data={barChartData}
        ></Bar>
      )}
    </>
  )
}

interface Props {
  vessel: VesselData | null
  startTime: number
  endTime: number
}

function TotalCO2Emitted({ vessel, startTime, endTime }: Props) {
  const { data, isFetching } = useGetCO2EmissionsBarChartInRangeQuery(
    {
      vesselId: (vessel && vessel.id) || '',
      mainEngineFlowMeters: (vessel && vessel.main_engines[0].flowMeter) || [],
      auxEngineFlowMeters: (vessel && vessel.auxiliary_engines[0].flowMeter) || [],
      startTime: startTime,
      endTime: endTime,
    },
    { skip: !vessel }
  )

  const dataArray = data ? [data] : undefined

  const barChartOptions = {
    maintainAspectRatio: false,
    responsive: true,

    interaction: {
      intersect: false,
      mode: 'index' as const,
    },

    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: dataArray?.[0]?.unitOfMeasurement,
          font: {
            size: 16,
          },
        },
      },
    },
    elements: {
      bar: {
        borderRadius: 4,
      },
    },
  } as ChartOptions

  let barChartData = {
    labels:
      dataArray?.[0]?.data.map((item: any) => {
        const timestamp = parseInt(item.category)
        return getUTCDateString(new Date(timestamp))
      }) || [],
    datasets: [
      ...(dataArray?.map((dataset: any) => ({
        labels: [],
        data: dataset.data.map((item: any) => ({ x: new Date(parseInt(item.category)), y: item.value })),
        backgroundColor: '#F5A445',
        maxBarThickness: 100,
      })) || []),
    ],
  } as ChartData

  const breakdownTableHeader = {
    label: {
      classname: 'bg-grayText',
      text: 'Date',
    },
    data: barChartData.labels || [],
  }

  const breakdownTableRows = [
    ...(dataArray?.map((dataset: any) => ({
      label: {
        classname: 'bg-sunburst-orange',
        text: 'Total',
      },
      data: dataset.data.map((item: any) => item.value),
    })) || []),
  ]

  return (
    <div>
      <div className="flex flex-col flex-1 bg-white rounded-xl border border-grayOutlineColor overflow-hidden h-2/4 mb-4">
        <div className="flex items-center border-b border-grayOutlineColor h-14 w-full px-4 gap-2">
          <p className="font-semibold text-sm lg:text-lg">Total CO2 emitted</p>
          <img
            src={InfoIcon}
            className="w-4 h auto"
            data-tooltip-id="total-fuel-consumed-tooltip"
            data-tooltip-delay-hide={200}
          ></img>
          <Tooltip
            id="total-fuel-consumed-tooltip"
            border="1px solid #8990A3"
            content="Total CO2 emitted based on selected interval"
            style={{
              width: '275px',
              borderRadius: '8px',
              backgroundColor: 'white',
              color: '#5D657A',
            }}
          ></Tooltip>
        </div>
        <div className="flex flex-col w-full items-center justify-center p-10">
          <div className="flex items-center justify-center h-96 w-full">
            {vessel && (
              <TotalCO2EmittedChart
                barChartData={barChartData}
                barChartOptions={barChartOptions}
                isFetching={isFetching}
              />
            )}
          </div>
        </div>
      </div>

      <BreakdownTable
        title="Breakdown table"
        header={breakdownTableHeader}
        rows={breakdownTableRows}
      />
    </div>
  )
}

export default TotalCO2Emitted
