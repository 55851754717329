import { createAsyncThunk } from '@reduxjs/toolkit'
import { auth } from '../../../firebase'
import { signInWithEmailAndPassword, browserSessionPersistence, setPersistence } from 'firebase/auth'

const login = createAsyncThunk('auth/login', async ({ email, password }: { email: string; password: string }) => {
  await signInWithEmailAndPassword(auth, email, password)
  await setPersistence(auth, browserSessionPersistence)
})

export { login }
