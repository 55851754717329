interface Label {
  text: string
  classname: string
}

interface TableRow {
  label?: Label
  data: any[]
}

interface Props {
  title: string
  header: TableRow
  rows: TableRow[]
}

function BreakdownTable({ title, header, rows }: Props) {
  return (
    <div className="flex flex-col flex-1 bg-white rounded-xl border border-grayOutlineColor overflow-hidden h-2/4">
      <div className="flex items-center border-b border-grayOutlineColor h-14 w-full px-4">
        <p className="font-semibold text-sm lg:text-lg">{title}</p>
      </div>

      <div className="overflow-y-auto">
        <div className="overflow-x-auto">
          <table className="w-full table-auto">
            <thead>
              <tr className="h-10 border-b border-grayOutlineColor bg-[#F7F8FA]">
                {header.label && (
                  <th className="w-36 px-4">
                    <div className="flex items-center gap-6">
                      <div className={`w-4 h-4 rounded-md ${header.label.classname}`}></div>
                      <p className="text-grayText font-normal">{header.label.text}</p>
                    </div>
                  </th>
                )}
                {header.data.map((item: string, index: any) => (
                  <th
                    className="px-4"
                    key={index}
                  >
                    <p className="font-normal text-left text-nowrap text-grayText">{item}</p>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((row: TableRow, index: number) => (
                <tr
                  key={index}
                  className="h-10 border-b border-grayOutlineColor"
                >
                  {row.label && (
                    <td className="w-36 px-4">
                      <div className="flex items-center gap-6">
                        <div className={`w-4 h-4 rounded-md ${row.label.classname}`}></div>
                        <p className="text-grayText">{row.label.text}</p>
                      </div>
                    </td>
                  )}
                  {row.data.map((item: any, index: number) => (
                    <td
                      key={index}
                      className="px-4"
                    >
                      <p className="text-grayText">{item}</p>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default BreakdownTable
