import { useSelector } from 'react-redux'
import { analyticsState } from 'store/slices/analyticsSlice'
import AnalyticsChart from './AnalyticsChart'
import { VesselData } from 'interfaces/vessel'
import Loader from 'components/Loader'

interface Props {
  vessel: VesselData | null
  vesselFetching: boolean
}

function AnalyticsChartWrapper({ vessel, vesselFetching }: Props) {
  const { graph1IsFetching, graph2IsFetching } = useSelector(analyticsState)

  const isLoading = graph1IsFetching || graph2IsFetching || vesselFetching

  return (
    <div className="min-w-32 relative min-h-[20rem] min-height-900:min-h-[25rem] min-height-1000:min-h-[32rem] min-height-1100:min-h-[38rem] min-height-1200:min-h-[44rem] min-height-1300:min-h-[52rem] min-height-1400:min-h-[60rem]">
      {vessel && (
        <AnalyticsChart
          vessel={vessel}
          isLoading={isLoading}
        />
      )}
      {isLoading && (
        <div
          className="absolute top-0 left-0 z-10 w-full h-full flex justify-center items-center bg-slate-100 opacity-80"
          style={{ backgroundColor: 'white' }}
        >
          <Loader />
        </div>
      )}
    </div>
  )
}

export default AnalyticsChartWrapper
