import classNames from 'classnames'
import { ReactComponent as Icon } from 'assets/icons/ArrowDownCircledLight.svg'

interface Props {
  percentage?: number
  isReversed?: boolean
}

const Percentage = ({ percentage = 0, isReversed = false }: Props) => {
  let statusColor
  const statusImageClassName = percentage < 0 ? '' : 'rotate-180'

  if (isReversed) {
    statusColor = percentage > 0 ? 'bad' : 'good'
  } else {
    statusColor = percentage < 0 ? 'bad' : 'good'
  }

  return (
    <div>
      <div className="flex pl-2 h-8 gap-1">
        <Icon
          className={classNames(
            'w-4 h-4 mt-0.5',
            statusColor === 'bad' ? 'fill-coral-blaze' : 'fill-lush-meadow',
            statusImageClassName
          )}
        />

        <p className={classNames('text-sm', statusColor === 'bad' ? 'text-coral-blaze' : 'text-lush-meadow')}>
          {percentage}%
        </p>
      </div>
    </div>
  )
}

export default Percentage
