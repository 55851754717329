import classNames from 'classnames'

interface Props {
  text: string
  color: string
}

function ColoredDataBlock({ text, color }: Props) {
  const bgColor = color.replace('hsl', 'hsla').replace(')', ', 0.15)')
  return (
    <div>
      <div
        className="rounded"
        style={{ color: color, backgroundColor: bgColor }}
      >
        <p className="text-sm px-2 py-2">{text}</p>
      </div>
    </div>
  )
}

export default ColoredDataBlock
