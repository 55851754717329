import classNames from 'classnames'
import { useState } from 'react'

export interface ButtonGroupItem {
  label: string
  value: string
}

interface Props {
  buttons: ButtonGroupItem[]
  value: string
  onChange: (value: any) => void
  disabled?: boolean
}

function ButtonGroup({ buttons, value, onChange, disabled }: Props) {
  const [selectedButton, setSelectedButton] = useState(value)

  function handleButtonClick(value: string) {
    onChange(value)
    setSelectedButton(value)
  }

  function isFirstButton(value: string) {
    return value === buttons[0].value
  }

  function isLastButton(value: string) {
    return value === buttons[buttons.length - 1].value
  }

  const buttonCssClasses = classNames('h-10 px-4 text-sm lg:text-lg border-y border-grayOutlineColor', {
    'cursor-not-allowed': disabled,
    'opacity-50': disabled,
  })

  const buttonsItems = buttons.map((button) => (
    <button
      key={button.value}
      className={classNames(buttonCssClasses, {
        'border-l': !isLastButton(button.value),
        'border-r': !isFirstButton(button.value),
        'rounded-l-lg': isFirstButton(button.value),
        'rounded-r-lg': isLastButton(button.value),
        'bg-primary': selectedButton === button.value,
        'bg-white text-gray': selectedButton !== button.value,
      })}
      onClick={() => handleButtonClick(button.value)}
      disabled={disabled}
    >
      {button.label}
    </button>
  ))

  return <div>{buttonsItems}</div>
}

export default ButtonGroup
