import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import ChartWrapper from './ChartWrapper'
import Loader from 'components/Loader'

ChartJS.register(ArcElement, Tooltip, Legend)
ChartJS.defaults.font.family = 'Lato'
ChartJS.defaults.font.size = 14

interface PieChartData {
  name: string
  value: number
  unit: string
  color: string
}

interface Props {
  data: PieChartData[]
  loading: boolean
  title: string
  tooltipText?: string
  tooltipId?: string
}

function PieChart({ data, loading, title, tooltipText, tooltipId }: Props) {
  const chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
  }
  const chartData = {
    labels: [...data.map((item) => item.name)],
    datasets: [
      {
        data: [...data.map((item) => item.value)],
        backgroundColor: [...data.map((item) => item.color)],
        borderWidth: 2,
      },
    ],
  }

  return (
    <ChartWrapper
      title={title}
      tooltipText={tooltipText}
      tooltipId={tooltipId}
    >
      {loading ? (
        <Loader />
      ) : (
        <div className="w-full flex items-center max-lg:flex-col">
          <div className="h-64 lg:h-48 xl:h-52 3xl:h-64">
            <Pie
              data={chartData}
              options={chartOptions}
            />
          </div>
          <div className="flex flex-col flex-1 content-end pl-5 pb-1 text-sm mt-4">
            {data.map((item, index) => (
              <div
                key={index}
                className="flex justify-between mb-3"
              >
                <div className="flex items-center">
                  <div
                    className="w-2 h-2 rounded-full mr-[0.45rem]"
                    style={{ backgroundColor: item.color }}
                  ></div>
                  <div>{item.name}</div>
                </div>
                <div className="text-right ml-2 text-silver-mist">
                  {item.value} {item.unit}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </ChartWrapper>
  )
}

export default PieChart
