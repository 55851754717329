import { PerformanceOverviewPeriod, VesselData } from 'interfaces/vessel'

export function extractPerformanceData(vessel: VesselData | null, selectedPeriod: PerformanceOverviewPeriod) {
  const dataMap = {
    previous_week: vessel?.performance_overview.previous_week,
    previous_month: vessel?.performance_overview.previous_month,
    previous_3_months: vessel?.performance_overview.previous_3_months,
  }

  const data = dataMap[selectedPeriod]

  const totalFuelConsumedData = data?.find((item) => item.name === 'totalFuelConsumed')
  const averageFuelEfficiency = data?.find((item) => item.name === 'averageFuelEfficiency')
  const totalCO2Emissions = data?.find((item) => item.name === 'totalCO2Emissions')
  const averageSFOC = data?.find((item) => item.name === 'averageSFOC')
  const totalDistance = data?.find((item) => item.name === 'totalDistance')

  return {
    totalFuelConsumedData,
    averageFuelEfficiency,
    totalCO2Emissions,
    averageSFOC,
    totalDistance,
  }
}
