import classNames from 'classnames'
import { ReactComponent as CheckIcon } from 'assets/icons/Check.svg'

interface Props {
  checked?: boolean
  disabled?: boolean
}

function Checkmark({ checked, disabled }: Props) {
  return (
    <div
      className={classNames('border w-4 h-4 border-grayOutlineColor rounded', {
        'bg-pure-white': !checked,
        'bg-skyward-wave': checked,
        'border-skyward-wave': checked,
        'bg-whisper-gray': disabled,
      })}
    >
      {checked && <CheckIcon className="w-3 h-3 fill-pure-white mt-[0.05rem] ml-[0.05rem]" />}
    </div>
  )
}

export default Checkmark
