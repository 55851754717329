import { SourceFilters, analyticsState } from '../../../store/slices/analyticsSlice'
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  ScatterController,
  Legend,
  BarElement,
  CategoryScale,
} from 'chart.js'
import type { ChartData, ChartOptions, ChartType } from 'chart.js'
import { Chart } from 'react-chartjs-2'
import { useSelector } from 'react-redux'
import { metricsOptions } from '../data/metricsOptions'
import { VesselData } from 'interfaces/vessel'
import NoData from 'components/utility/NoData'
import { getUTCTimeString } from 'shared/date/getUTCTimeString'
import { getUTCDateString } from 'shared/date/getUTCDateString'

ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  Legend,
  ScatterController,
  BarElement,
  CategoryScale
)
ChartJS.defaults.font.family = 'Lato'
ChartJS.defaults.font.size = 14

interface Props {
  vessel: VesselData
  isLoading: boolean
}

interface Scales {
  x?: any
  y?: any
  y1?: any
}

type UnitOfRange = 'hours' | 'days' | 'weeks'

function AnalyticsChart({ vessel, isLoading }: Props) {
  const {
    graph1Filters,
    graph2Filters,
    currentChartData: { graph1, graph2 },
  } = useSelector(analyticsState)

  const vesselLastUpdated = vessel?.last_updated?._seconds || 0

  const graphType = graph1?.type ? graph1.type : graph2?.type ? graph2.type : null

  let chartDataAndOptions = null

  if (graphType) {
    chartDataAndOptions = getChartDataAndOptions(graphType, graph1, graph2, graph1Filters, graph2Filters)
  }

  const isGraphReady = graphType && chartDataAndOptions && (graph1Filters.metric || graph2Filters.metric)
  const isDataAvailable = !!chartDataAndOptions?.data?.datasets?.length

  function getChartLabel(options: any, filterData: SourceFilters) {
    const option = options.find(({ value }: any) => value === filterData.metric?.value)
    const selectedEngineLabels = filterData.selectedEngines.map(({ label }) => label)
    return filterData.metric?.value === 'fuelEfficiency'
      ? `${option.label} [${option.description}]`
      : `${selectedEngineLabels.toString()}: ${option.label} [${option.description}]`
  }

  function getBarChartLabel(flowMeterId: string, metricOptions: any, filterData: SourceFilters) {
    const selectedMetric = metricOptions.find(({ value }: any) => value === filterData.metric?.value)
    const engineOption = filterData.selectedEngines.find((item) => item.flowMeterId === parseInt(flowMeterId))
    return `${engineOption?.label}: ${selectedMetric?.label} [${selectedMetric?.description}]`
  }

  function getChartDataAndOptions(
    type: string,
    graph1: any,
    graph2: any,
    graph1Filters: SourceFilters,
    graph2Filters: SourceFilters
  ) {
    // Scatter
    if (type === 'scatter') {
      const datasets: any[] = []
      if (graph1.data.scatterData?.length && graph1Filters.metric) {
        datasets.push({
          type: 'scatter',
          label: getChartLabel(metricsOptions, graph1Filters),
          data: graph1.data.scatterData.map((item: any) => ({
            x: item.valueX ?? null,
            y: item.valueY ?? null,
          })),
          backgroundColor: '#223FA7',
          yAxisID: 'y',
        })
      }
      if (graph2.data.scatterData?.length && graph2Filters.metric) {
        datasets.push({
          type: 'scatter',
          label: getChartLabel(metricsOptions, graph2Filters),
          data: graph2.data.scatterData.map((item: any) => ({
            x: item.valueX ?? null,
            y: item.valueY ?? null,
          })),
          backgroundColor: '#22A73F',
          yAxisID: 'y1',
        })
      }

      if (graph1.data.trendLine?.length && graph1Filters.drawTrendline) {
        datasets.push({
          type: 'line',
          label: 'Trendline',
          data: graph1.data.trendLine.map((item: any) => ({
            x: item.valueX ?? null,
            y: item.valueY ?? null,
          })),
          backgroundColor: '#223FA7',
          borderColor: '#223FA7',
          pointRadius: 0,
          pointHitRadius: 0,
          yAxisID: 'y',
        })
      }
      if (graph2.data.trendLine?.length && graph2Filters.drawTrendline) {
        datasets.push({
          type: 'line',
          label: 'Trendline',
          data: graph2.data.trendLine.map((item: any) => ({
            x: item.valueX ?? null,
            y: item.valueY ?? null,
          })),
          backgroundColor: '#22A73F',
          borderColor: '#22A73F',
          pointRadius: 0,
          pointHitRadius: 0,
          yAxisID: 'y1',
        })
      }

      const scales: Scales = {}
      if (graph1.data.scatterData && graph1Filters.metric) {
        scales.y = {
          type: 'linear' as const,
          position: 'left' as const,
          title: {
            display: true,
            text: getChartLabel(metricsOptions, graph1Filters),
            font: {
              size: 16,
            },
          },
        }
      }
      if (graph2.data.scatterData && graph2Filters.metric) {
        scales.y1 = {
          type: 'linear' as const,
          position: 'right' as const,
          title: {
            display: true,
            text: getChartLabel(metricsOptions, graph2Filters),
            font: {
              size: 16,
            },
          },
        }
      }

      return {
        options: {
          maintainAspectRatio: false,
          plugins: {
            tooltip: {
              enabled: false,
            },
            legend: {
              position: 'bottom' as const,
              labels: {
                usePointStyle: true,
                pointStyle: 'rectRounded',
                padding: 10,
                font: {
                  size: 16,
                },
              },
            },
          },
          scales,
        } as ChartOptions,
        data: {
          datasets,
        } as ChartData,
      }
    }

    // Bar
    else if (type === 'bar') {
      const graphData = graph1.data.length ? graph1 : graph2
      const filterData = graph1.data.length ? graph1Filters : graph2Filters
      const selectedEngines = filterData.selectedEngines
      const selectedMeters = selectedEngines.map(({ flowMeterId }) => flowMeterId)
      const unitOfRange: UnitOfRange = graphData?.data?.[0]?.unitOfRange || 'days'

      const selectedDatasets = []
      if (selectedMeters.length) {
        for (let i = 0; i < selectedMeters.length; i++) {
          const meterId = selectedMeters[i]
          const dataset = graphData.data.find((item: any) => parseInt(item.label) === meterId)
          selectedDatasets.push(dataset)
        }
      }

      return {
        options: {
          maintainAspectRatio: false,
          responsive: true,
          interaction: {
            intersect: false,
            mode: 'index' as const,
          },
          plugins: {
            legend: {
              position: 'bottom' as const,

              labels: {
                usePointStyle: true,
                pointStyle: 'rectRounded',
                padding: 10,
                font: {
                  size: 16,
                },
              },
            },
            tooltip: {
              usePointStyle: true,
              callbacks: {
                label: function (context: any) {
                  const elementTime = context.raw.x.getTime() / 1000
                  if (elementTime > vesselLastUpdated) {
                    return 'Data not available'
                  } else {
                    return `${context.dataset.label}: ${context.raw.y}`
                  }
                },
              },
            },
          },
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
              title: {
                display: true,
                text: graphData?.[0]?.unitOfMeasurement,
                font: {
                  size: 16,
                },
              },
            },
          },
          elements: {
            bar: {
              borderRadius: 4,
            },
          },
        } as ChartOptions,
        data: {
          labels:
            selectedDatasets[0]?.data.map((item: any) => {
              const timestamp = parseInt(item.category)
              const dayString = getUTCDateString(new Date(timestamp))
              if (unitOfRange === 'hours') {
                const timeString = getUTCTimeString(new Date(timestamp))
                return `${dayString} ${timeString}`
              } else if (unitOfRange === 'weeks') {
                const lastDayOfWeek = new Date(timestamp)
                lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6)
                const lastDayString = getUTCDateString(lastDayOfWeek)
                return `${dayString} - ${lastDayString}`
              } else {
                return dayString
              }
            }) || [],
          datasets: [
            ...(selectedDatasets?.map((dataset: any) => ({
              label: getBarChartLabel(dataset.label, metricsOptions, filterData),
              data: dataset.data.map((item: any) => ({ x: new Date(parseInt(item.category)), y: item.value })),
              backgroundColor: getBarChartLabel(dataset.label, metricsOptions, filterData).includes('M/E')
                ? '#223FA7'
                : '#2EA5DB',
              maxBarThickness: 100,
            })) || []),
          ],
        },
      }
    }

    // Line chart
    else {
      const xlabels: any[] = []
      const timestamps: any[] = []
      let dataset1Values: number[] = []
      let dataset2Values: number[] = []

      const labelSource = graph1.data.length ? graph1.data : graph2.data

      for (let i = 0; i < labelSource.length; i++) {
        const item = labelSource[i]
        const date = new Date(item.startDate._seconds * 1000)
        xlabels.push(date.toUTCString())
      }

      for (let i = 0; i < graph1.data.length; i++) {
        const item = graph1.data[i]
        timestamps.push(item.startDate._seconds)
      }

      for (let i = 0; i < graph1.data.length; i++) {
        const item = graph1.data[i]
        dataset1Values.push(item.value)
      }

      for (let i = 0; i < graph2.data.length; i++) {
        const item = graph2.data[i]
        dataset2Values.push(item.value)
      }

      const datasets: any = []
      if (graph1.data.length && graph1Filters.metric) {
        datasets.push({
          type: graph1.type as ChartType,
          label: getChartLabel(metricsOptions, graph1Filters),
          data: dataset1Values,
          backgroundColor: '#223FA7',
          borderColor: '#223FA7',
          borderWidth: 2,
          segment: {
            borderColor: (context: any) => {
              const segmentTimestamp = timestamps[context.p1DataIndex]
              return segmentTimestamp > vesselLastUpdated ? 'transparent' : '#223FA7'
            },
          },
          tension: 0.2,
          yAxisID: 'y',
          pointStyle: 'rectRounded',
        })
      }
      if (graph2.data.length && graph2Filters.metric) {
        datasets.push({
          type: graph1.type as ChartType,
          label: getChartLabel(metricsOptions, graph2Filters),
          data: dataset2Values,
          backgroundColor: '#12B76A',
          borderColor: '#12B76A',
          borderWidth: 2,
          segment: {
            borderColor: (context: any) => {
              const segmentTimestamp = timestamps[context.p1DataIndex]
              return segmentTimestamp > vesselLastUpdated ? 'transparent' : '#12B76A'
            },
          },
          tension: 0.2,
          yAxisID: 'y1',
          pointStyle: 'rectRounded',
        })
      }

      const scales: Scales = {}
      scales.x = {
        beginAtZero: true,
        ticks: {
          maxRotation: 0,
          minRotation: 0,
          autoSkip: true,
          autoSkipPadding: 30,
          callback: (value: any, index: any, values: any) => {
            const firstDateString = xlabels[0]
            const lastDateString = xlabels[xlabels.length - 1]
            const firstDate = new Date(firstDateString)
            const lastDate = new Date(lastDateString)
            const differenceInHours = Math.abs((lastDate.getTime() - firstDate.getTime()) / (1000 * 3600))
            const dateString = xlabels[index]
            const date = new Date(dateString)
            let formattedDate = ''
            let formattedTime = getUTCTimeString(date)
            if (formattedTime === '24:00') {
              formattedTime = '00:00'
            }
            if (differenceInHours > 24) {
              formattedDate = getUTCDateString(date)
            }
            return [formattedTime, formattedDate]
          },
        },
      }
      if (graph1.data.length && graph1Filters.metric) {
        scales.y = {
          beginAtZero: true,
          position: 'left',
          ticks: {
            count: 7,
            display: true,
          },
          title: {
            display: true,
            text: getChartLabel(metricsOptions, graph1Filters),
            font: {
              size: 16,
            },
          },
        }
      }
      if (graph2.data.length && graph2Filters.metric) {
        scales.y1 = {
          beginAtZero: true,
          position: 'right',
          ticks: {
            count: 7,
            display: true,
          },
          title: {
            display: true,
            text: getChartLabel(metricsOptions, graph2Filters),
            font: {
              size: 16,
            },
          },
        }
      }

      return {
        options: {
          maintainAspectRatio: false,
          devicePixelRatio: 2,
          interaction: {
            intersect: false,
            mode: 'index',
          },
          elements: {
            point: {
              radius: 0,
            },
          },
          scales,
          plugins: {
            tooltip: {
              usePointStyle: true,
              callbacks: {
                title: function (context: any) {
                  const dateTime = timestamps[context[0].dataIndex]
                  const date = new Date(dateTime * 1000)
                  return `${getUTCDateString(date)} ${getUTCTimeString(date)}`
                },
                label: function (context: any) {
                  const segmentTimestamp = timestamps[context.dataIndex]
                  return segmentTimestamp > vesselLastUpdated
                    ? 'Data not available'
                    : `${context.dataset.label}: ${context.raw}`
                },
              },
            },
            legend: {
              position: 'bottom',
              fullSize: true,
              labels: {
                usePointStyle: true,
                pointStyle: 'rectRounded',
                padding: 10,
                font: {
                  size: 16,
                },
              },
            },
          },
        } as ChartOptions,
        data: {
          labels: Array.from({ length: dataset1Values.length || dataset2Values.length }, () => ''),
          datasets,
        } as ChartData,
      }
    }
  }

  return (
    <>
      {chartDataAndOptions && isGraphReady && isDataAvailable && (
        <Chart
          options={chartDataAndOptions.options}
          type={graphType as ChartType}
          data={chartDataAndOptions.data}
        ></Chart>
      )}
      {!isGraphReady && !isLoading && (
        <div className="pt-32">
          <NoData
            type="warning"
            title="No data available"
            text="Please, select data source."
          />
        </div>
      )}
      {!isDataAvailable && isGraphReady && !isLoading && (
        <div className="pt-32">
          <NoData
            type="warning"
            title="No data available for selected period"
            text="Please, try different date range."
          />
        </div>
      )}
    </>
  )
}

export default AnalyticsChart
