import classNames from 'classnames'

interface Props {
  children: any
  className?: string
}

function ContentWrapper({ children, className }: Props) {
  return <div className={classNames('p-7 lg:p-8 xl:p-9', className)}>{children}</div>
}

export default ContentWrapper
