import Gif from 'assets/images/giphy.gif'
const NotFound = () => {
  return (
    <div className="w-screen not-phone:h-screen flex text-center ">
      <div className="mx-auto mt-8 align-middle">
        <p className="text-[28px] font-semibold">404 - Page Not Found</p>
        <p className="mb-4">Sorry, the page you are looking for could not be found.</p>
        <img
          className="border rounded-lg"
          src={Gif}
        />
      </div>
    </div>
  )
}

export default NotFound
