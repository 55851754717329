import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import Loader from 'components/Loader'

const PrivateRoute = ({ children }: any) => {
  const { user, authIsLoading } = useSelector((state: RootState) => {
    return state.auth
  })

  if (!user) {
    if (!authIsLoading) {
      return <Navigate to="/login" />
    }

    if (authIsLoading) {
      return (
        <div className="w-full h-[100vh] flex justify-center items-center">
          <Loader />
        </div>
      )
    }
  }

  if (user) {
    return children
  }
}

PrivateRoute.propTypes = {
  children: PropTypes.node,
}

export default PrivateRoute
