import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'
import { functions } from '../../firebase'
import { httpsCallable } from 'firebase/functions'
import { GraphQueryArgs, LineGraphData } from 'interfaces/graphApi'

const inTimeGraphApi = createApi({
  reducerPath: 'inTimeGraphApi',
  baseQuery: fakeBaseQuery(),
  keepUnusedDataFor: 600,
  endpoints(build) {
    return {
      getPowerInRangeInTime: build.query<LineGraphData[], GraphQueryArgs>({
        queryFn: async ({ vesselId, shaftMeterId, startTime, endTime }: GraphQueryArgs) => {
          try {
            const getData = httpsCallable(functions, 'getPowerInRangeInTime')
            const result: any = await getData({ vesselId, shaftMeterId, startTime, endTime })
            return { data: result.data[0] }
          } catch (error) {
            return { error }
          }
        },
      }),
      getTorqueInRangeInTime: build.query<LineGraphData[], GraphQueryArgs>({
        queryFn: async ({ vesselId, shaftMeterId, startTime, endTime }: GraphQueryArgs) => {
          try {
            const getData = httpsCallable(functions, 'getTorqueInRangeInTime')
            const result: any = await getData({ vesselId, shaftMeterId, startTime, endTime })
            return { data: result.data[0] }
          } catch (error) {
            return { error }
          }
        },
      }),
      getRPMInRangeInTime: build.query<LineGraphData[], GraphQueryArgs>({
        queryFn: async ({ vesselId, shaftMeterId, startTime, endTime }: GraphQueryArgs) => {
          try {
            const getData = httpsCallable(functions, 'getRPMInRangeInTime')
            const result: any = await getData({ vesselId, shaftMeterId, startTime, endTime })
            return { data: result.data[0] }
          } catch (error) {
            return { error }
          }
        },
      }),
      getMassFlowRateInRangeInTime: build.query<LineGraphData[], GraphQueryArgs>({
        queryFn: async ({ vesselId, flowMeterId, startTime, endTime, isSplit }: GraphQueryArgs) => {
          try {
            const getData = httpsCallable(functions, 'getMassFlowRateInRangeInTime')
            const result: any = await getData({ vesselId, flowMeterId, startTime, endTime, isSplit })
            return { data: result.data[0] }
          } catch (error) {
            return { error }
          }
        },
      }),
      getSFOCInTime: build.query<LineGraphData[], GraphQueryArgs>({
        queryFn: async ({ vesselId, flowMeterId, shaftMeterId, startTime, endTime, isSplit }: GraphQueryArgs) => {
          try {
            const getData = httpsCallable(functions, 'getSFOCInTime')
            const result: any = await getData({ vesselId, flowMeterId, shaftMeterId, startTime, endTime, isSplit })
            return { data: result.data[0] }
          } catch (error) {
            return { error }
          }
        },
      }),
      getSOGInRangeInTime: build.query<LineGraphData[], GraphQueryArgs>({
        queryFn: async ({ vesselId, startTime, endTime }: GraphQueryArgs) => {
          try {
            const getData = httpsCallable(functions, 'getSOGInRangeInTime')
            const result: any = await getData({ vesselId, startTime, endTime })
            return { data: result.data[0] }
          } catch (error) {
            return { error }
          }
        },
      }),
      getTotalFuelEfficiencyInTime: build.query<LineGraphData[], GraphQueryArgs>({
        queryFn: async ({ vesselId, allEngineFlowMeters, startTime, endTime }: GraphQueryArgs) => {
          try {
            const getData = httpsCallable(functions, 'getTotalFuelEfficiencyInTime')
            const result: any = await getData({ vesselId, allEngineFlowMeters, startTime, endTime })
            return { data: result.data[0] }
          } catch (error) {
            return { error }
          }
        },
      }),
    }
  },
})

export const {
  useGetPowerInRangeInTimeQuery,
  useGetTorqueInRangeInTimeQuery,
  useGetRPMInRangeInTimeQuery,
  useGetMassFlowRateInRangeInTimeQuery,
  useGetSFOCInTimeQuery,
  useGetTotalFuelEfficiencyInTimeQuery,
  useGetSOGInRangeInTimeQuery,
} = inTimeGraphApi
export { inTimeGraphApi }
