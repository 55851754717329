import { useState } from 'react'
import { functions } from '../../firebase'
import { httpsCallable } from 'firebase/functions'
import { handleError } from '../../errors/errors'

const ExcelService = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<any>(null)

  const downloadReport = async (
    vesselName: string,
    vesselId: string,
    startTime: number,
    endTime: number
  ) => {
    try {
      setLoading(true)
      const downloadReport = httpsCallable(functions, 'downloadReport')
      downloadReport({ vesselId, startTime, endTime })
        .then((result: any) => {
          setLoading(false)
          if (result.data[0]) {
            const byteCharacters = atob(result.data[0])
            const byteNumbers = new Array(byteCharacters.length)
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i)
            }
            const byteArray = new Uint8Array(byteNumbers)
            const blob = new Blob([byteArray], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = `${vesselName} ${formatMilliseconds(startTime)} - ${formatMilliseconds(endTime)}.xlsx`
            link.click()
          }
        })
        .catch((error: any) => {
          setLoading(false)
          console.error(error)
        })
    } catch (error: any) {
      setLoading(false)
      setError(handleError(error.message))
    }
  }

  function formatMilliseconds(milliseconds: number) {
    const date = new Date(milliseconds)
    const day = ('0' + date.getDate()).slice(-2)
    const month = ('0' + (date.getMonth() + 1)).slice(-2)
    const year = date.getFullYear()

    return `${day}.${month}.${year}`
  }

  return { loading, downloadReport, error }
}

export default ExcelService
