import classNames from 'classnames'

interface Props {
  children: any
  className?: string
}

function PageHeading({ children, className }: Props) {
  return <h1 className={classNames('font-semibold text-xl lg:text-2xl xl:text-4xl', className)}>{children}</h1>
}

export default PageHeading
