import PageHeading from 'components/typography/PageHeading'
import ContentWrapper from '../components/layout/ContentWrapper'

function HelpCenter() {
  return (
    <ContentWrapper>
      <div className="flex flex-col mb-8">
        <PageHeading>Help centre</PageHeading>
        <p className="text-grayText text-sm lg:text-lg">
          Get answers to common questions, or contact us for personal support.
        </p>
      </div>

      <div className="bg-white rounded-xl flex-grow border border-grayOutlineColor overflow-hidden">
        <div className="flex justify-between w-full py-4 border-b border-grayOutlineColor px-3 lg:px-6">
          <div className="flex justify-between items-center w-full">
            <p className="font-semibold text-lg mx-4">Frequently asked questions</p>
          </div>
        </div>

        <div className="flex flex-col align-stretch flex-1 w-full pb-32 h-full overflow-auto text-lg">
          <div className="flex w-full flex-col">
            <div className="flex flex-col justify-center py-4 border-b mx-4 border-grayOutlineColor px-3 lg:px-6">
              <p className="font-semibold">How do I invite team members to join Periscope?</p>
              <p className="text-darkGrayText">
                To invite team members, navigate to the 'Users' view in your dashboard. Enter the email addresses and
                assign roles for each new member. Invitations will be sent out via email for them to join.
              </p>
            </div>
            <div className="flex flex-col justify-center py-4 border-b mx-4 border-grayOutlineColor px-3 lg:px-6">
              <p className="font-semibold">How can I access vessel analytics on Periscope?</p>
              <p className="text-darkGrayText">
                Vessel analytics can be accessed from the 'Analytics' section of the dashboard. Here you can select the
                specific vessel you wish to analyze and view detailed performance data and trends.
              </p>
            </div>
            <div className="flex flex-col justify-center py-4 border-b mx-4 border-grayOutlineColor px-3 lg:px-6">
              <p className="font-semibold">What steps should I follow to filter data in Periscope?</p>
              <p className="text-darkGrayText">
                To filter data, use the filter options available on the top of each data view. Select the parameters you
                wish to apply, such as date range, vessel type, or any other available criteria to refine your data set.
              </p>
            </div>
            <div className="flex flex-col justify-center py-4 border-b mx-4 border-grayOutlineColor px-3 lg:px-6">
              <p className="font-semibold">Can I change how data charts are displayed?</p>
              <p className="text-darkGrayText">
                Currently, the chart type is set automatically based on the data source and selected metrics for
                optimized display. We are always looking to improve, so stay tuned for future updates!
              </p>
            </div>
            <div className="flex flex-col justify-center py-4 border-b mx-4 border-grayOutlineColor px-3 lg:px-6">
              <p className="font-semibold">How do I change my password?</p>
              <p className="text-darkGrayText">
                If you need to change your password, go to the 'Settings' section of your dashboard. There you'll find
                the option to update your password with a new one.
              </p>
            </div>
          </div>
        </div>
      </div>
    </ContentWrapper>
  )
}

export default HelpCenter
