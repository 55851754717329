import classNames from 'classnames'

interface Props {
  children: any
  className?: string
}

function FormLabel({ children, className }: Props) {
  return (
    <div className={classNames('form-label font-semibold text-[12px] text-grayText mb-1.5', className)}>{children}</div>
  )
}

export default FormLabel
