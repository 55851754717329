import classNames from 'classnames'
import { ReactComponent as Icon } from 'assets/images/ship-wheel.svg'

interface Props {
  onClick?: any
  type?: 'button' | 'submit' | 'reset'
  children: React.ReactNode
  primary?: boolean
  secondary?: boolean
  danger?: boolean
  semibold?: boolean
  bold?: boolean
  large?: boolean
  fullWidth?: boolean
  loading?: boolean
  disabled?: boolean
  className?: string
  style?: React.CSSProperties
}

function Button({
  onClick,
  children,
  primary,
  secondary,
  danger,
  semibold,
  bold,
  large,
  fullWidth,
  loading,
  disabled,
  ...rest
}: Props) {
  const classes = classNames(
    rest.className,
    'flex items-center justify-center px-4 rounded-lg',
    {
      'h-10 text-sm lg:text-lg': !large,
      'bg-pure-white text-cosmic-black border border-grayOutlineColor': primary,
      'bg-skyward-wave text-pure-white': secondary,
      'bg-fiery-coral text-pure-white': danger,
      'font-semibold': semibold,
      'font-bold': bold,
      'h-12 text-sm lg:text-lg': large,
      'w-full': fullWidth,
      'opacity-80': loading,
      'opacity-50': disabled,
      'cursor-not-allowed': loading || disabled,
    },
    rest.style
  )

  return (
    <button
      onClick={onClick}
      type={rest.type}
      {...rest}
      disabled={loading || disabled}
      className={classes}
    >
      {loading ? (
        <Icon
          className={classNames('w-6 h-6  mr-1 animate-spin', {
            'fill-pure-white': !primary,
            'fill-cosmic-black': primary,
          })}
        />
      ) : (
        children
      )}
    </button>
  )
}

Button.propTypes = {
  checkVariationValue: ({ primary, secondary, danger }: Props) => {
    const count = Number(!!primary) + Number(!!secondary) + Number(!!danger)

    if (count > 1) {
      return new Error('Only one of primary, secondary, danger can be true')
    }
  },
}

export default Button
