import { useSelector } from 'react-redux'
import { SourceFilters, analyticsState } from '../../../store/slices/analyticsSlice'
import PowerInRangeInTimeLoader from '../chart-loaders/PowerInRangeInTimeLoader'
import TorqueInRangeInTimeLoader from '../chart-loaders/TorqueInRangeInTimeLoader'
import RPMInRangeInTimeLoader from '../chart-loaders/RPMInRangeInTimeLoader'
import MassFlowRateInRangeInTimeLoader from '../chart-loaders/MassFlowRateInRangeInTimeLoader'
import SOGInRangeInTimeLoader from '../chart-loaders/SOGInRangeInTimeLoader'
import SFOCInTimeLoader from '../chart-loaders/SFOCInTimeLoader'
import TotalFuelEfficiencyInTimeLoader from '../chart-loaders/TotalFuelEfficiencyInTimeLoader'
import TotalFuelConsumedBarChartInRangeLoader from '../chart-loaders/TotalFuelConsumedBarChartInRangeLoader'
import FuelEfficiencyInKnotsLoader from '../chart-loaders/FuelEfficiencyInKnotsLoader'
import PowerInKnotsLoader from '../chart-loaders/PowerInKnotsLoader'
import TorqueInKnotsLoader from '../chart-loaders/TorqueInKnotsLoader'
import RPMInKnotsLoader from '../chart-loaders/RPMInKnotsLoader'
import MassFlowRateInKnotsLoader from '../chart-loaders/MassFlowRateInKnotsLoader'
import SFOCInKnotsLoader from '../chart-loaders/SFOCInKnotsLoader'
import FuelEfficiencyInPowerLoader from '../chart-loaders/FuelEfficiencyInPowerLoader'
import SFOCInPowerLoader from '../chart-loaders/SFOCInPowerLoader'
import TorqueInPowerLoader from '../chart-loaders/TorqueInPowerLoader'
import RPMInPowerLoader from '../chart-loaders/RPMInPowerLoader'
import MassFlowRateInPowerLoader from '../chart-loaders/MassFlowRateInPowerLoader'
import SOGInPowerLoader from '../chart-loaders/SOGInPowerLoader'
import { SplitSensor, VesselData } from 'interfaces/vessel'

interface Props {
  vessel: VesselData
}

interface QueryParams {
  vesselId: string
  shaftMeterId?: number
  flowMeterId?: number
  isSplit: boolean
  allEngineFlowMeters: SplitSensor[]
  mainEngineFlowMeters?: SplitSensor[]
  auxEngineFlowMeters?: SplitSensor[]
}

function AnalyticsChartLoader({ vessel }: Props) {
  const { graph1Filters, graph2Filters, measuredBy } = useSelector(analyticsState)

  function loadGraphData(graphFilters: SourceFilters, graphNr: number) {
    if (!graphFilters.selectedEngines.length) return

    const metric = graphFilters.metric?.value
    const engineData = graphFilters.selectedEngines[0]

    const queryParams: QueryParams = {
      vesselId: vessel.id,
      flowMeterId: engineData.flowMeterId,
      shaftMeterId: engineData.shaftMeterId,
      isSplit: engineData.isSplit,
      mainEngineFlowMeters: vessel.main_engines[0].flowMeter,
      auxEngineFlowMeters: vessel.auxiliary_engines[0].flowMeter,
      allEngineFlowMeters: [...vessel.main_engines[0].flowMeter, ...vessel.auxiliary_engines[0].flowMeter],
    }

    if (measuredBy === 'time') {
      if (metric === 'fuelConsumed') {
        return (
          <TotalFuelConsumedBarChartInRangeLoader
            graphNr={graphNr}
            chartType={'bar'}
            queryParams={queryParams}
          />
        )
      } else {
        // don't allow second loader in case where fuelConsumed metric is selected for either of sources
        if (graph1Filters.metric?.value === 'fuelConsumed' || graph2Filters.metric?.value === 'fuelConsumed') return
        switch (metric) {
          case 'shaftPower':
            return (
              <PowerInRangeInTimeLoader
                graphNr={graphNr}
                chartType={'line'}
                queryParams={queryParams}
              />
            )
          case 'torque':
            return (
              <TorqueInRangeInTimeLoader
                graphNr={graphNr}
                chartType={'line'}
                queryParams={queryParams}
              />
            )
          case 'shaftSpeed':
            return (
              <RPMInRangeInTimeLoader
                graphNr={graphNr}
                chartType={'line'}
                queryParams={queryParams}
              />
            )
          case 'massFlowRate':
            return (
              <MassFlowRateInRangeInTimeLoader
                graphNr={graphNr}
                chartType={'line'}
                queryParams={queryParams}
              />
            )
          case 'fuelEfficiency':
            return (
              <TotalFuelEfficiencyInTimeLoader
                graphNr={graphNr}
                chartType={'line'}
                queryParams={queryParams}
              />
            )
          case 'sfoc':
            return (
              <SFOCInTimeLoader
                graphNr={graphNr}
                chartType={'line'}
                queryParams={queryParams}
              />
            )
          case 'sog':
            return (
              <SOGInRangeInTimeLoader
                graphNr={graphNr}
                chartType={'line'}
                queryParams={queryParams}
              />
            )
        }
      }
    }

    if (measuredBy === 'sog') {
      switch (metric) {
        case 'fuelEfficiency':
          return (
            <FuelEfficiencyInKnotsLoader
              graphNr={graphNr}
              chartType={'scatter'}
              queryParams={queryParams}
            />
          )
        case 'shaftPower':
          return (
            <PowerInKnotsLoader
              graphNr={graphNr}
              chartType={'scatter'}
              queryParams={queryParams}
            />
          )
        case 'torque':
          return (
            <TorqueInKnotsLoader
              graphNr={graphNr}
              chartType={'scatter'}
              queryParams={queryParams}
            />
          )
        case 'shaftSpeed':
          return (
            <RPMInKnotsLoader
              graphNr={graphNr}
              chartType={'scatter'}
              queryParams={queryParams}
            />
          )
        case 'massFlowRate':
          return (
            <MassFlowRateInKnotsLoader
              graphNr={graphNr}
              chartType={'scatter'}
              queryParams={queryParams}
            />
          )
        case 'sfoc':
          return (
            <SFOCInKnotsLoader
              graphNr={graphNr}
              chartType={'scatter'}
              queryParams={queryParams}
            />
          )
      }
    }

    if (measuredBy === 'power') {
      switch (metric) {
        case 'fuelEfficiency':
          return (
            <FuelEfficiencyInPowerLoader
              graphNr={graphNr}
              chartType={'scatter'}
              queryParams={queryParams}
            />
          )
        case 'sfoc':
          return (
            <SFOCInPowerLoader
              graphNr={graphNr}
              chartType={'scatter'}
              queryParams={queryParams}
            />
          )
        case 'torque':
          return (
            <TorqueInPowerLoader
              graphNr={graphNr}
              chartType={'scatter'}
              queryParams={queryParams}
            />
          )
        case 'shaftSpeed':
          return (
            <RPMInPowerLoader
              graphNr={graphNr}
              chartType={'scatter'}
              queryParams={queryParams}
            />
          )
        case 'massFlowRate':
          return (
            <MassFlowRateInPowerLoader
              graphNr={graphNr}
              chartType={'scatter'}
              queryParams={queryParams}
            />
          )
        case 'sog':
          return (
            <SOGInPowerLoader
              graphNr={graphNr}
              chartType={'scatter'}
              queryParams={queryParams}
            />
          )
      }
    }
  }

  const metric1 = graph1Filters.metric?.value
  const metric2 = graph2Filters.metric?.value

  return (
    <>
      {metric1 && loadGraphData(graph1Filters, 1)}
      {metric2 && loadGraphData(graph2Filters, 2)}
    </>
  )
}

export default AnalyticsChartLoader
