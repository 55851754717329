import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'
import { functions } from '../../firebase'
import { httpsCallable } from 'firebase/functions'
import { VesselData, VesselStatsData } from 'interfaces/vessel'

const vesselsApi = createApi({
  reducerPath: 'vesselsApi',
  baseQuery: fakeBaseQuery(),
  keepUnusedDataFor: 86400,
  tagTypes: ['Vessel'],
  endpoints(build) {
    return {
      // Get all vessels for users page
      // Even though the request to backend doesn't require any parameters, we need to pass something to the queryFn
      // to delete cache from previous user if working with multiple accounts
      getAllVessels: build.query<VesselData[], string>({
        providesTags: ['Vessel'],
        queryFn: async (userId) => {
          try {
            const getAllVessels = httpsCallable(functions, 'getAllVessels')
            const result: any = await getAllVessels()
            return { data: result.data[0] }
          } catch (error) {
            throw error
          }
        },
      }),
      // Vessel list for the startpage (with SFOC and Fuel Efficiency calculations)
      // Even though the request to backend doesn't require any parameters, we need to pass something to the queryFn
      // to delete cache from previous user if working with multiple accounts
      getVesselList: build.query<VesselStatsData[], string>({
        queryFn: async (userId) => {
          try {
            const getAllVessels = httpsCallable(functions, 'getVesselList')
            const result: any = await getAllVessels()
            return { data: result.data[0] }
          } catch (error) {
            throw error
          }
        },
      }),
      getVesselById: build.query<VesselData, string>({
        queryFn: async (vesselId) => {
          try {
            const getVessel = httpsCallable(functions, 'getVessel')
            const result: any = await getVessel({ vesselId })
            return { data: result.data[0] }
          } catch (error) {
            throw error
          }
        },
      }),
      addUserToVessel: build.mutation<any, { vesselId: string; uid: string }>({
        invalidatesTags: ['Vessel'],
        queryFn: async ({ vesselId, uid }) => {
          try {
            const addUserToVesselFn = httpsCallable(functions, 'addUserToVessel')
            const result: any = await addUserToVesselFn({ vesselId, uid })
            const data: any = result.data

            if (!data[1]) {
              throw new Error(data[2])
            }

            return data[0]
          } catch (error: any) {
            throw error
          }
        },
      }),
      removeUserFromVessel: build.mutation<any, { vesselId: string; uid: string }>({
        invalidatesTags: ['Vessel'],
        queryFn: async ({ vesselId, uid }) => {
          try {
            const removeUserFromVesselFn = httpsCallable(functions, 'removeUserFromVessel')
            const result: any = await removeUserFromVesselFn({ vesselId, uid })
            const data: any = result.data

            if (!data[1]) {
              throw new Error(data[2])
            }

            return data[0]
          } catch (error: any) {
            throw error
          }
        },
      }),
    }
  },
})

export const {
  useGetAllVesselsQuery,
  useGetVesselListQuery,
  useGetVesselByIdQuery,
  useAddUserToVesselMutation,
  useRemoveUserFromVesselMutation,
} = vesselsApi
export { vesselsApi }
