import { Chart as ChartJS, LinearScale, Legend, CategoryScale, BarElement, Tooltip as ChartTooltip } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import Loader from 'components/Loader'
import ChartWrapper from 'components/chart/ChartWrapper'

ChartJS.register(LinearScale, CategoryScale, BarElement, Legend, ChartTooltip)
ChartJS.defaults.font.family = 'Lato'
ChartJS.defaults.font.size = 14

function FuelConsumed({ data, loading }: { data: any; loading: boolean }) {
  const barChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
      intersect: false,
      mode: 'index' as const,
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    elements: {
      bar: {
        borderRadius: 4,
      },
    },
  }

  let barChartData = {
    labels:
      data?.map((item: any) => {
        return item.vesselName
      }) || [],
    datasets: [
      ...(data?.map((dataset: any) => ({
        label: dataset.vesselName,
        data: [{ x: dataset.vesselName, y: dataset.fuelConsumedAmount }],
        backgroundColor: dataset.color,
        maxBarThickness: 70,
      })) || []),
    ],
  }

  return (
    <ChartWrapper
      title="Fuel consumed"
      tooltipText="Total fuel consumed based on selected interval"
      tooltipId="fuel-consumed-bar-chart"
      className="h-64 lg:h-48 xl:h-52 3xl:h-64 w-full"
    >
      {loading ? (
        <Loader />
      ) : (
        <Bar
          options={barChartOptions}
          data={barChartData}
        ></Bar>
      )}
    </ChartWrapper>
  )
}

export default FuelConsumed
