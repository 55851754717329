import classNames from 'classnames'

interface Props {
  className?: string
  children: any
}

interface FormGroupControlBaseProps<T, K> {
  value?: T
  onChange?: (_value: K) => void
  errorMessage?: string
  helperText?: string
}

function FormGroup({ children, className }: Props) {
  return <div className={classNames('form-group mb-4', className)}>{children}</div>
}

export default FormGroup
export type { FormGroupControlBaseProps }
