export const enum ErrorCodes {
  INSUFFICIENT_PERMISSION = 'insufficient-permission',
  EMAIL_ALREADY_EXISTS = 'email-already-exists',
  ID_TOKEN_EXPIRED = 'id-token-expired',
  INTERNAL_ERROR = 'internal-error',
  INVALID_CREDENTIAL = 'invalid-credential',
  INVALID_EMAIL = 'invalid-email',
  INVALID_TOKEN = 'invalid-id-token',
  INVALID_PASSWORD = 'invalid-password',
  INVALID_UID = 'invalid-uid',
  MISSING_UID = 'missing-uid',
  TOO_MANY_REQUESTS = 'too-many-requests',
  USER_NOT_FOUND = 'user-not-found',
  WRONG_PASSWORD = 'wrong-password',
  USER_DISABLED = 'user-disabled',
}
