import { Chart as ChartJS, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js'
import { Scatter } from 'react-chartjs-2'
import Loader from 'components/Loader'
import ChartWrapper from 'components/chart/ChartWrapper'

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend)
ChartJS.defaults.font.family = 'Lato'
ChartJS.defaults.font.size = 14

function FuelEfficiencySFOC({ data, loading }: { data: any; loading: boolean }) {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  }

  const chartData = {
    datasets: [
      ...(data?.map((dataset: any) => ({
        label: dataset.vesselName,
        data: [{ x: dataset.sfocAmount, y: dataset.fuelEfficiencyAmount }],
        backgroundColor: dataset.color,
        pointStyle: 'circle',
        hoverRadius: 9,
        pointRadius: 9,
      })) || []),
    ],
  }

  return (
    <ChartWrapper
      title="Avg. Fuel efficiency / Avg. SFOC"
      tooltipText="Average Fuel efficiency against Average Specific Fuel Oil Consumption based on selected interval"
      tooltipId="fuel-efficiency-sfoc-bar-chart"
      className="min-h-64 lg:min-h-48 xl:min-h-52 3xl:min-h-64"
    >
      {false ? (
        <Loader />
      ) : (
        <div className="flex flex-col w-full">
          <div>
            <Scatter
              options={options}
              data={chartData}
            />
          </div>
          <div className="pl-5 pb-1 text-sm mt-4 text-center">
            {data.map((item: any, index: number) => (
              <div
                key={index}
                className="inline-block mr-4"
              >
                <div className="flex items-center">
                  <div
                    className="w-2 h-2 rounded-full mr-[0.45rem]"
                    style={{ backgroundColor: item.color }}
                  ></div>
                  <div className="whitespace-nowrap">{item.vesselName}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </ChartWrapper>
  )
}

export default FuelEfficiencySFOC
