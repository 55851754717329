import { useDispatch, useSelector } from 'react-redux'
import { analyticsState, changeErrorStatus } from '../../../store/slices/analyticsSlice'
import { changeGraphIsFetching, updateCurrentChartData, useGetFuelEfficiencyInKnotsQuery } from '../../../store'
import { useEffect } from 'react'

interface Props {
  graphNr: number
  chartType: string
  queryParams: {
    vesselId: string
    flowMeterId?: number
    shaftMeterId?: number
    isSplit?: boolean
  }
}

function FuelEfficiencyInKnotsLoader({ graphNr, chartType, queryParams }: Props) {
  const dispatch = useDispatch()
  const { dateFilters, graph1IsFetching, graph2IsFetching } = useSelector(analyticsState)

  const { data, isFetching, error } = useGetFuelEfficiencyInKnotsQuery({
    vesselId: queryParams.vesselId,
    flowMeterId: queryParams.flowMeterId,
    startTime: dateFilters.startTime,
    endTime: dateFilters.endTime,
    isSplit: queryParams.isSplit,
  })

  useEffect(() => {
    if (data && !graph1IsFetching && !graph2IsFetching) {
      dispatch(updateCurrentChartData({ graphNr, chartType, data }))
    }
  }, [data, graph1IsFetching, graph2IsFetching])

  useEffect(() => {
    dispatch(changeGraphIsFetching({ isFetching, graphNr }))
  }, [isFetching])

  useEffect(() => {
    dispatch(changeErrorStatus({ graphNr, error }))
  }, [error])

  return <></>
}

export default FuelEfficiencyInKnotsLoader
