import classNames from 'classnames'

interface Props {
  children: any
  className?: string
  maxHeight?: string
}

function PageWrapper({ children, className, maxHeight = 'calc(100vh - 5rem)' }: Props) {
  return (
    <div
      className={classNames('page-wrapper overflow-x-auto flex-1 bg-primary', className)}
      style={{ maxHeight }}
    >
      {children}
    </div>
  )
}

export default PageWrapper
