import { useState } from 'react'
import EyeIcon from '../assets/eye_icon.png'
import ReusableInputField from '../components/Input'
import ContentWrapper from '../components/layout/ContentWrapper'
import Button from 'components/Button'

function Settings() {
  const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>(false)
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)
  const [currentPassword, setCurrentPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [confirmCurrentPassword, setConfirmCurrentPassword] = useState<string>('')

  const handleCurrentPassword = (currentPassword: string) => {
    setCurrentPassword(currentPassword)
  }

  const handleNewPassword = (newPassword: string) => {
    setNewPassword(newPassword)
  }

  const handleConfirmNewPassword = (confirmNewPassword: string) => {
    setConfirmCurrentPassword(confirmNewPassword)
  }

  const toggleCurrentPasswordVisibility = () => {
    setShowCurrentPassword(!showCurrentPassword)
  }

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword)
  }

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  return (
    <ContentWrapper>
      <div className="flex flex-col justify-between h-28">
        <div className="flex font-semibold text-4xl">Settings</div>

        <div>
          <div className="flex gap-4">
            <p className="underline decoration-2 underline-offset-8 decoration-accentColor text-lg font-semibold">
              Personal
            </p>
            <p className="text-lg font-semibold">Organization</p>
          </div>
          <div className="bg-grayOutlineColor w-full h-0.5"></div>
        </div>
      </div>

      <div className="bg-white rounded-t-xl mt-10 border border-grayOutlineColor overflow-hidden">
        <div className="flex justify-between w-full h-16 border-b border-grayOutlineColor px-6">
          <div className="flex flex-col justify-center">
            <p className="font-semibold text-lg">Change password</p>
          </div>
        </div>

        <div className="flex flex-col flex-1 w-full h-full max-h-screen overflow-y-auto">
          <div className="px-6 py-4">
            <div className="mb-4 w-[458px]">
              <ReusableInputField
                label="Current password"
                type={showCurrentPassword ? 'text' : 'password'}
                value={currentPassword}
                onChange={handleCurrentPassword}
                placeholder="Enter your current password"
                width="458px"
                height="48px"
                icon={EyeIcon}
                onIconClick={toggleCurrentPasswordVisibility}
                required
              />
            </div>

            <div className="mb-4 w-[458px]">
              <ReusableInputField
                label="New password"
                type={showNewPassword ? 'text' : 'password'}
                value={newPassword}
                onChange={handleNewPassword}
                placeholder="Enter your new password"
                width="458px"
                height="48px"
                icon={EyeIcon}
                onIconClick={toggleNewPasswordVisibility}
                required
              />
            </div>

            <div className="mb-4 w-[458px]">
              <ReusableInputField
                label="Confirm new password"
                type={showConfirmPassword ? 'text' : 'password'}
                value={confirmCurrentPassword}
                onChange={handleConfirmNewPassword}
                placeholder="Confirm your new password"
                width="458px"
                height="48px"
                icon={EyeIcon}
                onIconClick={toggleConfirmPasswordVisibility}
                required
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white flex items-center justify-end w-full h-20 border-b border-x rounded-b-xl border-grayOutlineColor px-6 py-4">
        <Button secondary>Change password</Button>
      </div>
    </ContentWrapper>
  )
}

export default Settings
