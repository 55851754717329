import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import { vesselsApi } from './apis/vesselsApi'
import { inTimeGraphApi } from './apis/inTimeGraphApi'
import { totalsGraphApi } from './apis/totalsGraphApi'
import { inKnotsGraphApi } from './apis/inKnotsGraphApi'
import { inPowerGraphApi } from './apis/inPowerGraphApi'
import { analyticsReducer } from './slices/analyticsSlice'
import { authReducer } from './slices/authSlice'
import { userApi } from './apis/userApi'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [userApi.reducerPath]: userApi.reducer,
    [vesselsApi.reducerPath]: vesselsApi.reducer,
    [inTimeGraphApi.reducerPath]: inTimeGraphApi.reducer,
    [inKnotsGraphApi.reducerPath]: inKnotsGraphApi.reducer,
    [inPowerGraphApi.reducerPath]: inPowerGraphApi.reducer,
    [totalsGraphApi.reducerPath]: totalsGraphApi.reducer,
    analytics: analyticsReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(
      userApi.middleware,
      inTimeGraphApi.middleware,
      vesselsApi.middleware,
      totalsGraphApi.middleware,
      inKnotsGraphApi.middleware,
      inPowerGraphApi.middleware
    )
  },
})

setupListeners(store.dispatch)

export * from 'store/thunks/auth/login'
export * from 'store/thunks/auth/logout'
export * from 'store/thunks/auth/validateAndSetNewPassword'
export * from 'store/thunks/auth/getUserCustomClaims'

export {
  useCreateUserMutation,
  useDeleteUserMutation,
  useGetAllUsersQuery,
  useResetPasswordMutation,
  useSetUserCustomClaimsMutation,
} from './apis/userApi'

export {
  useGetPowerInRangeInTimeQuery,
  useGetTorqueInRangeInTimeQuery,
  useGetRPMInRangeInTimeQuery,
  useGetMassFlowRateInRangeInTimeQuery,
  useGetSFOCInTimeQuery,
  useGetTotalFuelEfficiencyInTimeQuery,
  useGetSOGInRangeInTimeQuery,
} from './apis/inTimeGraphApi'

export {
  changeMeasuredBy,
  changeGraph1Source,
  changeGraph1Metric,
  changeGraph1Engines,
  changeGraph1TrendlineStatus,
  changeGraph2Source,
  changeGraph2Metric,
  changeGraph2Engines,
  changeGraph2TrendlineStatus,
  changeDateFilters,
  updateCurrentChartData,
  changeGraphIsFetching,
  changeErrorStatus,
} from './slices/analyticsSlice'

export { changeAuthIsLoading, changeInitiallyRequestedUrl, changeUser, changeAuthError } from './slices/authSlice'

export {
  useGetAllVesselsQuery,
  useGetVesselListQuery,
  useGetVesselByIdQuery,
  useAddUserToVesselMutation,
  useRemoveUserFromVesselMutation,
} from './apis/vesselsApi'

export {
  useGetAverageSFOCInRangeQuery,
  useGetSingleDataMetricsWithChangeInRangeQuery,
  useGetAverageFuelEfficiencyInRangeQuery,
  useGetTotalFuelConsumedBarChartInRangeQuery,
  useGetCO2EmissionsBarChartInRangeQuery,
} from './apis/totalsGraphApi'

export {
  useGetSFOCInKnotsQuery,
  useGetFuelEfficiencyInKnotsQuery,
  useGetPowerInKnotsQuery,
  useGetTorqueInKnotsQuery,
  useGetRPMInKnotsQuery,
  useGetMassFlowRateInKnotsQuery,
} from './apis/inKnotsGraphApi'

export {
  useGetFuelEfficiencyInPowerQuery,
  useGetSFOCInPowerQuery,
  useGetTorqueInPowerQuery,
  useGetRPMInPowerQuery,
  useGetMassFlowRateInPowerQuery,
  useGetSOGInPowerQuery,
} from './apis/inPowerGraphApi'

// Get the type of our store variable
export type AppStore = typeof store
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>
export type AppDispatch = AppStore['dispatch']
