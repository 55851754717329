interface Props {
  children: any
  errorMessage?: string
  helperText?: string
  disabled?: boolean
}

function FormGroupControl({ children, errorMessage, helperText, disabled }: Props) {
  return <div>{children}</div>
}

export default FormGroupControl
