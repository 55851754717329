import { VesselData } from 'interfaces/vessel'
import Loader from './Loader'

interface Props {
  vessel: VesselData | null
  isLoading: boolean
}

function VesselInfoCompact({ vessel, isLoading }: Props) {
  return (
    <div className="bg-white px-4 pt-5 pb-1 rounded-xl border border-grayOutlineColor">
      {isLoading ? (
        <div className=" w-full h-full flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <>
          <div className="text-cosmic-black text-2xl font-semibold leading-7 mb-4">{vessel?.name}</div>
          <div className="grid grid-cols-2 grid-rows-2">
            <div className="mb-4">
              <div className="text-silver-mist font-medium leading-[1.375rem]">IMO</div>
              <div className="text-cosmic-black font-semibold leading-[1.375rem]">{vessel?.imo}</div>
            </div>
            <div>
              <div className="text-silver-mist font-medium leading-[1.375rem]">Vessel type</div>
              <div className="text-cosmic-black font-semibold leading-[1.375rem]">{vessel?.vessel_type}</div>
            </div>
            <div className="mb-4">
              <div className="text-silver-mist font-medium leading-[1.375rem]">DWT</div>
              <div className="text-cosmic-black font-semibold leading-[1.375rem]">{vessel?.dwt}</div>
            </div>
            <div>
              <div className="text-silver-mist font-medium leading-[1.375rem]">Flag</div>
              <div className="text-cosmic-black text-sm font-semibold leading-[1.375rem]">{vessel?.country_tag}</div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default VesselInfoCompact
