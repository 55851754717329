import { MetricsValue } from '../../../store/slices/analyticsSlice'

export const metricsOptions = [
  {
    value: 'shaftPower' as MetricsValue,
    label: 'Shaft Power',
    description: 'kW',
  },
  {
    value: 'torque' as MetricsValue,
    label: 'Torque',
    description: 'kNm',
  },
  {
    value: 'shaftSpeed' as MetricsValue,
    label: 'Shaft Speed',
    description: 'RPM',
  },
  {
    value: 'volumeFlowRate' as MetricsValue,
    label: 'Volume flow rate',
    description: 'm3/h',
  },
  {
    value: 'massFlowRate' as MetricsValue,
    label: 'Mass flow rate',
    description: 'kg/h',
  },
  {
    value: 'fuelConsumed' as MetricsValue,
    label: 'Fuel consumed',
    description: 'mt',
  },
  // {
  //   value: 'flowRate' as MetricsValue,
  //   label: 'Flow rate',
  //   description: 'm3/h',
  // },
  {
    value: 'fuelEfficiency' as MetricsValue,
    label: 'Fuel Efficiency',
    description: 'kg/nm',
  },
  {
    value: 'sfoc' as MetricsValue,
    label: 'SFOC',
    description: 'g/kWh',
  },
  {
    value: 'sog' as MetricsValue,
    label: 'SOG',
    description: 'kn',
  },
]
