import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'
import { getFunctions } from 'firebase/functions'

const firebaseConfig = {
  apiKey: 'AIzaSyBx2aNBRcN5M2TfqOIuD2U7voj8-t1HEnU',
  authDomain: 'periscope-e2ac5.firebaseapp.com',
  projectId: 'periscope-e2ac5',
  storageBucket: 'periscope-e2ac5.appspot.com',
  messagingSenderId: '789388723779',
  appId: '1:789388723779:web:8d2f91610454e17f21b6f6',
  measurementId: 'G-3VLRV156PV',
}

const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const auth = getAuth(app)
const functions = getFunctions(app, 'europe-west1')

export { auth, functions }
