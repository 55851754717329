import classNames from 'classnames'
import { ReactComponent as Icon } from 'assets/images/ship-wheel.svg'

function Loader() {
  return (
    <div className="mt-5 mb-5">
      <div
        className="w-32 h-32 flex items-center justify-center"
        aria-label="loading"
      >
        <Icon className="animate-spin fill-smoky-steel" />
      </div>
    </div>
  )
}

export default Loader
