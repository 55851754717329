import { MeasuredByValue, MetricsOption, SourceFilters } from '../../../store/slices/analyticsSlice'
import { metricsOptions } from '../data/metricsOptions'

function getDropdownOptions(graphFilters: SourceFilters, measuredBy: MeasuredByValue) {
  const selectedEngines = graphFilters.selectedEngines
  const enginesTypes = selectedEngines.map(({ type }) => type)

  const optionValues: string[] = []

  if (enginesTypes.length) {
    switch (measuredBy) {
      case 'time':
        if (graphFilters.source === 'other') {
          // Other
          optionValues.push('fuelEfficiency', 'sog')
        } else if (graphFilters.source === 'engines') {
          // Engines
          if (enginesTypes.includes('m/e') && enginesTypes.includes('a/e')) {
            // ME && AE
            optionValues.push('fuelConsumed')
          } else if (enginesTypes.includes('a/e')) {
            // AE only
            optionValues.push('massFlowRate', 'fuelConsumed')
          } else if (enginesTypes.includes('m/e')) {
            // ME
            optionValues.push('massFlowRate', 'shaftPower', 'torque', 'shaftSpeed', 'fuelConsumed', 'sfoc')
          }
        }
        break

      case 'sog':
        if (graphFilters.source === 'other') {
          // Other
          optionValues.push('fuelEfficiency')
        } else if (graphFilters.source === 'engines') {
          // Engines
          if (enginesTypes.includes('m/e') && enginesTypes.includes('a/e')) {
            // ME && AE
            // Currently no options
          } else if (enginesTypes.includes('a/e')) {
            // AE only
            optionValues.push('massFlowRate')
          } else if (enginesTypes.includes('m/e')) {
            // ME
            optionValues.push('massFlowRate', 'shaftPower', 'torque', 'shaftSpeed', 'sfoc')
          }
        }
        break

      case 'power':
        if (graphFilters.source === 'other') {
          // Other
          optionValues.push('fuelEfficiency', 'sog')
        } else if (graphFilters.source === 'engines') {
          // Engines
          if (enginesTypes.includes('m/e') && enginesTypes.includes('a/e')) {
            // ME && AE
            // Currently no options
          } else if (enginesTypes.includes('a/e')) {
            // AE only
            optionValues.push('massFlowRate')
          } else if (enginesTypes.includes('m/e')) {
            // ME
            optionValues.push('massFlowRate', 'torque', 'shaftSpeed', 'sfoc')
          }
        }
        break
    }
  }
  // If engine is not selected, no options shall be available

  const returnArray: MetricsOption[] = []

  optionValues.forEach((item: string) => {
    const match = metricsOptions.find(({ value }: MetricsOption) => value === item)
    if (match) {
      returnArray.push(match)
    }
  })

  return returnArray
}

export default getDropdownOptions
