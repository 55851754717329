import { Tooltip } from 'react-tooltip'
import { ReactComponent as InfoIcon } from 'assets/icons/Info.svg'
import classNames from 'classnames'

interface Props {
  children: React.ReactNode
  title: string
  tooltipText?: string
  tooltipId?: string
  className?: string
}

function ChartWrapper({ children, title, tooltipText, tooltipId, className }: Props) {
  return (
    <>
      <div className="flex flex-col flex-1 bg-white rounded-xl border border-grayOutlineColor overflow-hidden mb-4">
        <div className="flex items-center border-b border-grayOutlineColor h-14 w-full px-4 gap-2">
          <p className="font-semibold text-sm lg:text-lg">{title}</p>
          {!!tooltipText && (
            <>
              <InfoIcon
                className="w-4 h-4 fill-silver-mist"
                data-tooltip-id={tooltipId}
                data-tooltip-delay-hide={200}
              />
              <Tooltip
                id={tooltipId}
                border="1px solid #8990A3"
                content={tooltipText}
                style={{
                  width: '275px',
                  borderRadius: '8px',
                  backgroundColor: 'white',
                  color: '#5D657A',
                }}
              ></Tooltip>
            </>
          )}
        </div>
        <div className="flex flex-col w-full items-center justify-center p-4 2xl:p-6 3xl:p-10">
          <div className={classNames('flex items-center justify-center w-full', className)}>{children}</div>
        </div>
      </div>
    </>
  )
}

export default ChartWrapper
