import VesselIcon from 'assets/vessel_icon.png'
import { VesselData } from 'interfaces/vessel'
import Loader from './Loader'

interface Props {
  vessel: VesselData | null
  isLoading: boolean
}

function VesselInfo({ vessel, isLoading }: Props) {
  return (
    <>
      <div className="bg-white rounded-xl h-40 border border-grayOutlineColor w-full">
        {isLoading ? (
          <div className=" w-full h-full flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <>
            <div className="flex items-center h-24 w-full px-4 gap-4">
              <img
                src={VesselIcon}
                className="w-12 h-auto"
              ></img>

              <div className="flex flex-col">
                <p className="text-lg font-semibold"></p>
                <p className="text-lg font-semibold">{vessel?.name}</p>
                <p className="font-normal text-sm text-grayPlaceholder">IMO {vessel?.imo}</p>
              </div>

              <div className="flex h-6 gap-8 ml-auto pr-4"></div>
            </div>

            <div className="flex min-h-16 pb-1 border-t border-grayOutlineColor px-4 items-center gap-6">
              <div>
                <p className="lg:text-sm text-grayText">Vessel type</p>
                <p className="text-sm lg:text-lg">{vessel?.vessel_type}</p>
              </div>

              <div className="bg-grayOutlineColor h-10 w-px" />

              <div>
                <p className="lg:text-sm text-grayText">DWT</p>
                <div className="text-sm lg:text-lg">{vessel?.dwt}</div>
              </div>

              <div className="bg-grayOutlineColor h-10 w-px" />

              <div>
                <p className="lg:text-sm text-grayText">Year built</p>
                <div className="text-sm lg:text-lg">{vessel?.year_built}</div>
              </div>

              <div className="bg-grayOutlineColor h-10 w-px" />

              <div>
                <p className="lg:text-sm text-grayText">Flag</p>
                <div className="text-sm lg:text-lg">{vessel?.country_tag}</div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default VesselInfo
