import { ReactComponent as SmileySadIcon } from 'assets/icons/smileySad.svg'
import { ReactComponent as WarningOctagonIcon } from 'assets/icons/WarningOctagonFilled.svg'
import classNames from 'classnames'

function SadSmiley() {
  return (
    <SmileySadIcon
      className="h-5 w-5 text-silver-mist"
      fill="#8990A3"
    />
  )
}

function WarningOctagon() {
  return (
    <WarningOctagonIcon
      className="h-5 w-5 text-sunburst-orange"
      fill="#F5A445"
    />
  )
}

interface NoDataProps {
  type: 'sad' | 'warning'
  title: string
  text?: string
}

function NoData({ type, title, text }: NoDataProps) {
  return (
    <div className="flex flex-col justify-center items-center">
      <div
        className={classNames(`flex w-10 h-10 rounded-full justify-center items-center mb-3`, {
          'bg-morning-mist': type === 'sad',
          'bg-pale-sunrise': type === 'warning',
        })}
      >
        {type === 'sad' ? <SadSmiley /> : <WarningOctagon />}
      </div>
      <h2 className="text-cosmic-black text-sm font-medium mb-2">{title}</h2>
      {text && <p className="text-smoky-steel text-sm">{text}</p>}
    </div>
  )
}

export default NoData
