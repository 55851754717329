import classNames from 'classnames'
import Checkmark from 'components/form/Checkmark'
import ColoredDataBlock from 'components/tables/ColoredDataBlock'
import CustomTable, {
  CustomTableContentRow,
  CustomTableHeaderCol,
  CustomTableSettings,
} from 'components/tables/CustomTable'
import { PerformanceOverviewPeriod, VesselData } from 'interfaces/vessel'
import { getUTCDateString } from 'shared/date/getUTCDateString'
import { getUTCTimeString } from 'shared/date/getUTCTimeString'
import { extractPerformanceData } from 'shared/vessel/extractPerformanceData'

interface Props {
  vesselList: VesselData[] | undefined
  selectedPeriod: PerformanceOverviewPeriod
  loading: boolean
  selectedVessels: VesselData[] | undefined
  onVesselCheck: (vesselId: string) => void
}

function VesselPerformanceTable({ vesselList, selectedPeriod, loading, selectedVessels, onVesselCheck }: Props) {
  const totalFuelConsumedDataArr: number[] = []
  const totalCO2EmissionsArr: number[] = []
  const totalDistanceArr: number[] = []
  const averageFuelEfficiencyArr: number[] = []
  const averageSFOCArr: number[] = []
  const uncheckedGray = 'hsl(207, 0%, 75%)'

  if (vesselList) {
    for (const vessel of selectedVessels || vesselList) {
      const data = extractPerformanceData(vessel, selectedPeriod)
      data.totalFuelConsumedData && totalFuelConsumedDataArr.push(data.totalFuelConsumedData?.value)
      data.averageFuelEfficiency && averageFuelEfficiencyArr.push(data.averageFuelEfficiency?.value)
      data.totalCO2Emissions && totalCO2EmissionsArr.push(data.totalCO2Emissions?.value)
      data.averageSFOC && averageSFOCArr.push(data.averageSFOC?.value)
      data.totalDistance && totalDistanceArr.push(data.totalDistance?.value)
    }
  }

  function getColor(value: number, array: number[], reverse: boolean = false) {
    const max = Math.max(...array)
    const min = Math.min(...array)
    const range = max - min

    const startingHue = 126
    const startingSaturation = 79
    const startingLightness = 31
    const endingHue = 0
    const endingSaturation = 74
    const endingLightness = 53
    const hueDifference = startingHue - endingHue
    const saturationDifference = startingSaturation - endingSaturation
    const lightnessDifference = startingLightness - endingLightness
    const color = Math.floor(((value - min) / range) * 100)

    if (reverse) {
      if (value === max) {
        return 'hsl(126, 79%, 31%)'
      } else if (value === min) {
        return 'hsl(0, 74%, 53%)'
      }
      const hue = endingHue + color * (hueDifference / 100)
      const saturation = endingSaturation + color * (saturationDifference / 100)
      const lightness = endingLightness + color * (lightnessDifference / 100)
      return `hsl(${hue}, ${saturation}%, ${lightness}%)`
    }

    if (value === max) {
      return 'hsl(0, 74%, 53%)'
    } else if (value === min) {
      return 'hsl(126, 79%, 31%)'
    }
    const hue = startingHue - color * (hueDifference / 100)
    const saturation = startingSaturation - color * (saturationDifference / 100)
    const lightness = startingLightness - color * (lightnessDifference / 100)
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`
  }

  function isChecked(vesselId: string) {
    return selectedVessels?.find((vessel) => vessel.id === vesselId) ? true : false
  }

  const tableHeaderData: CustomTableHeaderCol[] = [
    {
      id: 'check',
      content: (
        <div className="min-w-4">
          <Checkmark disabled />
        </div>
      ),
    },
    {
      id: 'name',
      content: <p>Name</p>,
    },
    {
      id: 'lastUpdated',
      content: <p>Last updated</p>,
    },
    {
      id: 'fuelConsumed',
      content: <p>Fuel consumed</p>,
    },
    {
      id: 'CO2Emitted',
      content: <p>CO2 Emitted</p>,
    },
    {
      id: 'distanceSailed',
      content: <p>Distance sailed</p>,
    },
    {
      id: 'fuelEfficiency',
      content: <p>Avg. Fuel efficiency</p>,
    },
    {
      id: 'SFOC',
      content: <p>Avg. SFOC</p>,
    },
  ]

  const tableRowData: CustomTableContentRow[] =
    vesselList?.map((vessel, index) => {
      const timestamp = vessel.last_updated?._seconds || 0
      const lastUpdated =
        timestamp > 0
          ? `${getUTCDateString(new Date(timestamp * 1000))}, ${getUTCTimeString(new Date(timestamp * 1000))}`
          : 'No data available'

      const perfData = extractPerformanceData(vessel, selectedPeriod)

      return {
        id: `row-${vessel.id}`,
        haystack: [vessel.name.toLowerCase(), vessel.imo?.toString() || ''],
        onRowClick: () => onVesselCheck(vessel.id),
        cols: [
          {
            id: 'check',
            value: '',
            content: <Checkmark checked={isChecked(vessel.id)} />,
          },
          {
            id: 'name',
            value: vessel.name,
            content: (
              <div
                className={classNames({
                  'opacity-50': !isChecked(vessel.id),
                })}
              >
                <p className="mb-1 text-sm 2xl:text-lg font-semibold">{vessel.name}</p>
                <p className="text-grayText">IMO {vessel.imo}</p>
              </div>
            ),
          },
          {
            id: 'lastUpdated',
            value: timestamp,
            content: (
              <p
                className={classNames('text-grayText', {
                  'opacity-50': !isChecked(vessel.id),
                })}
              >
                {lastUpdated}
              </p>
            ),
          },
          {
            id: 'fuelConsumed',
            value: perfData.totalFuelConsumedData?.value || 0,
            content: (
              <ColoredDataBlock
                text={`${perfData.totalFuelConsumedData?.value} mt`}
                color={
                  isChecked(vessel.id)
                    ? getColor(perfData.totalFuelConsumedData?.value || 0, totalFuelConsumedDataArr)
                    : uncheckedGray
                }
              />
            ),
          },
          {
            id: 'CO2Emitted',
            value: perfData.totalCO2Emissions?.value || 0,
            content: (
              <ColoredDataBlock
                text={`${perfData.totalCO2Emissions?.value} mt`}
                color={
                  isChecked(vessel.id)
                    ? getColor(perfData.totalCO2Emissions?.value || 0, totalCO2EmissionsArr)
                    : uncheckedGray
                }
              />
            ),
          },
          {
            id: 'distanceSailed',
            value: perfData.totalDistance?.value || 0,
            content: (
              <ColoredDataBlock
                text={`${perfData.totalDistance?.value} nm`}
                color={
                  isChecked(vessel.id)
                    ? getColor(perfData.totalDistance?.value || 0, totalDistanceArr, true)
                    : uncheckedGray
                }
              />
            ),
          },
          {
            id: 'fuelEfficiency',
            value: perfData.averageFuelEfficiency?.value || 0,
            content: (
              <ColoredDataBlock
                text={`${perfData.averageFuelEfficiency?.value} kg/nm`}
                color={
                  isChecked(vessel.id)
                    ? getColor(perfData.averageFuelEfficiency?.value || 0, averageFuelEfficiencyArr)
                    : uncheckedGray
                }
              />
            ),
          },
          {
            id: 'SFOC',
            value: perfData.averageSFOC?.value || 0,
            content: (
              <ColoredDataBlock
                text={`${perfData.averageSFOC?.value} g/kWh`}
                color={
                  isChecked(vessel.id) ? getColor(perfData.averageSFOC?.value || 0, averageSFOCArr) : uncheckedGray
                }
              />
            ),
          },
        ],
      }
    }) || []

  const columnClassNames = [
    'w-12 2xl:w-16',
    'flex-1 min-w-40',
    'flex-1 min-w-40',
    'flex-1 min-w-40',
    'flex-1 min-w-40',
    'flex-1 min-w-40',
    'flex-1 min-w-40',
    'flex-1 min-w-40',
  ]

  const tableSettings: CustomTableSettings = {
    heading: 'Vessels',
    text: `Your fleet consists of ${tableRowData.length} vessels`,
    searchable: true,
    sortable: true,
    // smallText: true,
    columnExtraClassNames: columnClassNames,
    horizontallyScrollable: true,
    contentClassName: 'max-h-96',
    rowExtraClassName: 'py-4',
    sortableColumns: ['name', 'lastUpdated', 'fuelConsumed', 'CO2Emitted', 'distanceSailed', 'fuelEfficiency', 'SFOC'],
  }

  return (
    <div>
      <CustomTable
        settings={tableSettings}
        headerData={tableHeaderData}
        rowData={tableRowData}
        isLoading={loading}
      />
    </div>
  )
}

export default VesselPerformanceTable
