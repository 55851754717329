import { createAsyncThunk } from '@reduxjs/toolkit'
import { functions } from '../../../firebase'
import { httpsCallable } from 'firebase/functions'

const getUserCustomClaims = createAsyncThunk('auth/getUserCustomClaims', async ({ uid }: { uid: string }) => {
  const getUserCustomClaimsFunction = httpsCallable<string, any>(functions, 'getUserCustomClaims')
  const result = await getUserCustomClaimsFunction(uid)
  if (result.data.errorInfo) {
    return result.data.errorInfo.message
  }
  if (!result.data[1]) {
    return result.data[2]
  }
  return result.data[0]
})

export { getUserCustomClaims }
