/* 
A reusable table component with many features:
- Both table header cols and row cols can accept any type of content, including components or blocks of different nodes with their own props and functions
- Rows can be sorted by any column, by providing value prop for each column in the row
- Rows can be filtered by providing searchable strings for each row (haystack)
- Rows accept onRowClick prop as a function, which can be called when the row is clicked
- Table also accepts the optional settings prop which allows configuration options
- Maximum table height can be configured by adding classes through the contentClassName setting
- Table content will automatically become scrollable if the content overflows configured maximum content height
- Column widths can be configured via the columnExtraClassNames setting
- Table rows can be styled with the rowExtraClassName setting
- Sortable columns can be configured by providing an array of column ids in the sortableColumns setting
- The first item in the sortableColumns array will be the default column to sort by
*/

import React, { useState } from 'react'
import classNames from 'classnames'
import ReusableInputField from 'components/Input'
import Loader from 'components/Loader'
import { ReactComponent as SortAscIcon } from 'assets/icons/SortAscending.svg'
import { ReactComponent as SortDescIcon } from 'assets/icons/SortDescending.svg'

export interface Props {
  settings?: CustomTableSettings
  headerData: any[]
  rowData: any[] | undefined
  isLoading?: boolean
}

export interface CustomTableSettings {
  heading?: string
  text?: string
  searchable?: boolean
  sortable?: boolean
  smallText?: boolean
  columnExtraClassNames?: string[] // Additional classnames for each column, array with length equal to headerData
  horizontallyScrollable?: boolean
  contentClassName?: string
  rowExtraClassName?: string
  sortableColumns?: string[]
}

export interface CustomTableHeaderCol {
  id: string
  content: React.ReactNode
}

interface CustomTableContentCol {
  id: string
  value: string | number
  content: React.ReactNode
}

export interface CustomTableContentRow {
  id: string
  haystack: string[]
  onRowClick?: any
  cols: CustomTableContentCol[]
}

export default function CustomTable({ settings, headerData, rowData, isLoading }: Props) {
  const [searchQuery, setSearchQuery] = useState('')
  const [orderByColumn, setOrderByColumn] = useState(getInitialOrderByColumn())
  const [isAscending, setIsAscending] = useState(true)

  let colClassNames: string[] = []
  if (!settings?.columnExtraClassNames) {
    colClassNames = Array(headerData.length).fill('flex-1')
  } else {
    colClassNames = settings.columnExtraClassNames
  }

  const handleSearchChange = (value: string) => {
    setSearchQuery(value.toLowerCase())
  }

  function toggleSort() {
    setIsAscending(!isAscending)
  }

  function handleSortChange(index: number) {
    if (!settings?.sortable) return
    setOrderByColumn(index)
    if (orderByColumn === index) {
      toggleSort()
    }
  }

  function getColIndex(id: string) {
    return headerData.findIndex((col) => col.id === id)
  }

  function getInitialOrderByColumn() {
    if (settings?.sortable && settings?.sortableColumns) {
      return getColIndex(settings.sortableColumns[0])
    }
    return 0
  }

  // Allow sorting only for columns that are marked as sortable or if no columns are marked as sortable
  function isColSortable(id: string) {
    if (!settings?.sortable) return false
    if (settings?.sortable && settings?.sortableColumns) {
      return settings.sortableColumns.includes(id)
    }
    return true
  }

  const TableHeader = ({ headerData }: { headerData: CustomTableHeaderCol[] }) => {
    return (
      <div className=" text-smoky-steel w-full h-10">
        <div className="flex h-full items-center">
          {headerData.map((col: CustomTableHeaderCol, index: number) => (
            <div
              key={col.id}
              className={classNames(
                'bg-whisper-gray flex items-center h-full gap-2 pr-3  border-b border-grayOutlineColor',
                colClassNames && colClassNames[index],
                {
                  'cursor-pointer': settings?.sortable && isColSortable(col.id),
                  'pl-4 2xl:pl-6': index === 0,
                }
              )}
              onClick={() => isColSortable(col.id) && handleSortChange(index)}
            >
              {col.content}
              {settings?.sortable &&
                orderByColumn === index &&
                (isAscending ? (
                  <SortAscIcon className="w-auto h-3 fill-silver-mist" />
                ) : (
                  <SortDescIcon className="w-auto h-3 fill-silver-mist" />
                ))}
            </div>
          ))}
        </div>
      </div>
    )
  }

  const TableRows = ({ rowData }: { rowData: CustomTableContentRow[] | undefined }) => {
    const filteredRows = rowData?.filter((row: CustomTableContentRow) => {
      let matchFound = false
      row.haystack.forEach((haystack) => {
        if (haystack.includes(searchQuery)) {
          matchFound = true
        }
      })
      return matchFound
    })

    const sortedRows = settings?.sortable
      ? filteredRows?.sort((a: any, b: any) => {
          const aVal = a.cols[orderByColumn].value
          const bVal = b.cols[orderByColumn].value

          if (aVal < bVal) {
            return isAscending ? -1 : 1
          }
          if (aVal > bVal) {
            return isAscending ? 1 : -1
          }
          return 0
        })
      : filteredRows

    return (
      <div
        className={classNames(
          'flex flex-col align-stretch flex-1 w-full',
          settings?.contentClassName && settings.contentClassName
        )}
      >
        {sortedRows &&
          sortedRows.map((row: CustomTableContentRow) => (
            <div
              key={row.id}
              className={classNames('flex w-full', {
                'cursor-pointer': row.onRowClick,
              })}
              onClick={row.onRowClick && row.onRowClick}
            >
              <div className={classNames('flex w-full')}>
                {row.cols.map((col: CustomTableContentCol, index: number) => (
                  <div
                    key={col.id}
                    className={classNames(
                      'flex flex-col justify-center  border-b border-grayOutlineColor',
                      settings?.rowExtraClassName ? settings.rowExtraClassName : 'min-h-8 py-[0.6rem]',
                      colClassNames && colClassNames[index],
                      {
                        'pl-4 2xl:pl-6': index === 0,
                      }
                    )}
                  >
                    <div
                      key={col.id}
                      className={classNames('flex items-center gap-2 pr-3')}
                    >
                      {col.content}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
      </div>
    )
  }

  return (
    <div
      className={classNames('bg-white rounded-xl flex-grow border border-grayOutlineColor overflow-hidden', {
        'lg:text-sm xl:text-lg leading-5': !settings?.smallText,
        'text-[0.83rem] 2xl:text-lg leading-4': settings?.smallText,
      })}
    >
      <div className="flex justify-between w-full h-20 border-b border-grayOutlineColor px-4 2xl:px-6">
        {(!!settings?.heading || !!settings?.text) && (
          <div className="flex flex-col justify-center">
            {!!settings?.heading && <p className="font-semibold text-sm lg:text-lg leading-7">{settings?.heading}</p>}
            {settings?.text && (
              <p
                className={classNames(' text-smoky-steel leading-6', {
                  'lg:text-sm xl:text-lg': !settings?.smallText,
                  'text-[0.83rem] 2xl:text-lg': settings?.smallText,
                })}
              >
                {settings?.text}
              </p>
            )}
          </div>
        )}

        {settings?.searchable && (
          <div className="flex gap-4 justify-center items-center">
            <ReusableInputField
              placeholder="Search vessel"
              width="16rem"
              height="2.5rem"
              isSearchInput={true}
              value={searchQuery}
              onChange={handleSearchChange}
              className="text-sm lg:text-lg"
            />
          </div>
        )}
      </div>
      <div className={classNames(settings?.horizontallyScrollable && 'overflow-x-auto')}>
        <TableHeader headerData={headerData} />
        {isLoading ? (
          <div className="w-full flex justify-center mt-5 mb-5 items-center">
            <Loader />
          </div>
        ) : (
          <TableRows rowData={rowData} />
        )}
      </div>
      <div className="h-20"></div>
    </div>
  )
}
