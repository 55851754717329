import { Outlet } from 'react-router-dom'
import MainHeader from '../components/layout/MainHeader'

function HeaderLayout() {
  return (
    <>
      <MainHeader />
      <div>
        <div className="flex">
          <Outlet />
        </div>
      </div>
    </>
  )
}

export default HeaderLayout
