import { Switch } from '@headlessui/react'
import classNames from 'classnames'

interface Props {
  value: any
  onChange: (_value: boolean) => void
  disabled?: boolean
  label?: string
  className?: string
  size?: 'sm' | 'md' | 'lg'
}

function ToggleSwitch({ value, onChange, disabled, label, className, size = 'md' }: Props) {
  const sizeStyles = [
    {
      variant: 'sm',
      switch: 'min-h-[1.125rem] min-w-8',
      toggler: 'min-h-[0.844rem] min-w-[0.844rem]',
      togglerPosition1: 'translate-x-[0.16rem]',
      togglerPosition2: 'translate-x-[0.94rem]',
    },
    {
      variant: 'md',
      switch: 'min-h-6 min-w-[2.625rem]',
      toggler: 'min-h-[1.125rem] min-w-[1.125rem]',
      togglerPosition1: 'translate-x-1',
      togglerPosition2: 'translate-x-[1.34rem]',
    },
    {
      variant: 'lg',
      switch: 'min-h-8 min-w-14',
      toggler: 'min-h-6 min-w-6',
      togglerPosition1: 'translate-x-[0.3rem]',
      togglerPosition2: 'translate-x-7',
    },
  ]

  const getSizeStyles = sizeStyles.find(({ variant }) => variant === size)

  return (
    <div className="toggle-switch">
      <Switch.Group>
        <div className={classNames('flex justify-between', className)}>
          {label && label.length > 0 && <Switch.Label className="mr-4 max-xl:text-sm">{label}</Switch.Label>}

          <Switch
            checked={value}
            onChange={onChange}
            disabled={disabled}
            className={`${getSizeStyles?.switch} relative inline-flex items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
          >
            <span
              className={`${
                value ? getSizeStyles?.togglerPosition2 : getSizeStyles?.togglerPosition1
              } ${getSizeStyles?.toggler} inline-block transform rounded-full bg-white transition-transform`}
            />
          </Switch>
        </div>
      </Switch.Group>
    </div>
  )
}

export default ToggleSwitch
